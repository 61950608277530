import { ThunkAction } from 'redux-thunk';
import * as OpenInvitationService from '../services/open-invitation-service';
import * as SsoEmailGateService from '../services/sso-email-gate-service';
import * as SsoRedirectService from '../services/sso-redirect-service';
import { Action, createAction } from './action';
import { ErrorReasonTextAction, ExternalRedirect, IsStringNullOrEmpty } from '../misc/common';
import * as Models from '../misc/models';
import * as SigninBoxActions from './signin-box';
import State from '../reducers/state';
import { logError } from '../misc/error';
import { finishGetBranding } from '../actions/branding';
import { signinClearFailure } from '../actions/signin';
import { ErrorReason } from '../misc/models';
import { CmsManifestMetaData } from '../misc/cms-manifest-metadata';
import { AnyAction } from 'redux';

export const GetSettingsResultType = 'OPEN_INV_GET_SETTINGS_RESULT';
export type GetSettingsResultAction = Action<typeof GetSettingsResultType, Models.OpenInvitationSettings>;
export function getSettingsResult(result: Models.OpenInvitationSettings): GetSettingsResultAction {
    return createAction(GetSettingsResultType, result);
}

export const RegisterResultType = 'OPEN_INV_REGISTER_RESULT';
export type RegisterResultAction = Action<typeof RegisterResultType, Models.OpenInvitationRegisterResult>;
export function registerResult(result: Models.OpenInvitationRegisterResult): RegisterResultAction {
    return createAction(RegisterResultType, result);
}

export const AcceptPPType = 'OPEN_INV_ACCEPT_PP';
export type AcceptPPAction = Action<typeof AcceptPPType, null>;
export function acceptPP(): AcceptPPAction {
    return createAction(AcceptPPType, null);
}

export const SsoEmailGateResultType = 'SSO_EMAIL_GATE_RESULT';
export type SsoEmailGateResultAction = Action<typeof SsoEmailGateResultType, Models.SsoEmailGateResult>;
export function ssoEmailGateResult(result: Models.SsoEmailGateResult): SsoEmailGateResultAction {
    return createAction(SsoEmailGateResultType, result);
}

export function submitSsoEmailGate(email: string, recaptchaResponse: string, privacyPolicyCustomCheckboxAccepted: boolean | null, manifestMetaData: CmsManifestMetaData | null): ThunkAction<Promise<void>, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        dispatch(signinClearFailure());
        return new Promise<void>((resolve, reject) => {
            let state = getState();
            let id = state.openinvitation.id;
            SsoEmailGateService.submitSsoEmailGate(id, email, recaptchaResponse)
                .then(r => {
                    if (r.enforcedSsoProviderId > 0) {
                        SsoRedirect(id, email, r.enforcedSsoProviderId, privacyPolicyCustomCheckboxAccepted, manifestMetaData);
                    } else {
                        r.errorText = dispatch(ErrorReasonTextAction(r.error));
                        dispatch(ssoEmailGateResult(r));
                        resolve();
                    }
                })
                .catch(r => { logError('error submitting sso email gate data', r); resolve(); });
        });
    };
}

function SsoRedirect(id: string, email: string, ssoProviderId: number, privacyPolicyCustomCheckboxAccepted: boolean | null, manifestMetaData: CmsManifestMetaData | null) {
    SsoRedirectService.submitSsoRedirectUrlRequest(id, email, ssoProviderId, privacyPolicyCustomCheckboxAccepted, manifestMetaData)
        .then(r => {
            if (!IsStringNullOrEmpty(r.redirectUrl)) {
                ExternalRedirect(r.redirectUrl);
            } else {
                logError(`No SSO Redirect Url returned for provider ${ssoProviderId}`);
            }
        })
        .catch(r => logError(`error getting sso redirect url for provider ${ssoProviderId}`, r));
}

export function getSettings(id: string): ThunkAction<void, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        OpenInvitationService.GetSettings(id)
            .then(r => {
                if (r.brandingStyles !== undefined && r.brandingStyles.styles !== '') {
                    dispatch(finishGetBranding(r.brandingStyles));
                }
                dispatch(SigninBoxActions.changeLanguage(r.languageId));
                dispatch(getSettingsResult(r));
            })
            .catch(r => logError('error getting settings', r));
    };
}

export function submitOpenInvitation(forename: string, middlename: string, surname: string, email: string, confirmEmail: string, password: string, confirmPassword: string, recaptchaResponse: string, privacyPolicyCustomCheckboxAccepted: boolean | null, externalMetadata: Models.KeyValuePair[], manifestMetaData: CmsManifestMetaData | null): ThunkAction<Promise<void>, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        dispatch(signinClearFailure());
        return new Promise<void>((resolve, reject) => {
            let state = getState();
            let languageId = state.signinbox.language.id;
            let id = state.openinvitation.id;
            OpenInvitationService.submitOpenInvitation(id, forename, middlename, surname, email, confirmEmail, password, confirmPassword, recaptchaResponse, languageId, privacyPolicyCustomCheckboxAccepted, externalMetadata, manifestMetaData)
                .then(r => {
                    if (r.isSuccessRedirect) {
                        ExternalRedirect(r.redirectUrl);
                    } else {
                        if (r.error === ErrorReason.ProjectExpired) {
                            // If project was expired during registration process, then show expiration-page
                            ExternalRedirect(window.location.href);
                        } else {
                            r.errorText = dispatch(ErrorReasonTextAction(r.error));
                            dispatch(registerResult(r));
                            resolve();
                        }
                    }
                })
                .catch(r => { logError('error submitting open Invitation data', r); resolve(); });
        });
    };
}

export type Actions = GetSettingsResultAction | AcceptPPAction | RegisterResultAction | SsoEmailGateResultAction;