import { ThunkAction } from 'redux-thunk';
import { error } from 'util';
import State from './state';
import * as Models from '../misc/models';
import * as PasswordResetActions from '../actions/password-reset';

/**
 * The default initial state
 */
export const PasswordResetInitialState: State.PasswordReset = {
    errorMessage: '',
    error: 0,
    token: '',
    username: '',
    tokenIsValid: false,
    isSuccess: false,
    tokenValidated: false
};

/**
 * Reducer for password reset
 */
const PasswordResetRequestReducer = (state: State.PasswordReset = PasswordResetInitialState, action: PasswordResetActions.Actions): State.PasswordReset => {
    let result;
    switch (action.type) {
        case PasswordResetActions.ValidateTokenResultType:
            result = action.payload;
            return {
                ...state,
                token: result.token,
                username: result.username,
                error: result.error,
                tokenIsValid: result.tokenIsValid,
                tokenValidated: true
            };
        case PasswordResetActions.ResetResultType:
            result = action.payload;
            return {
                ...state,
                tokenIsValid: result.tokenIsValid,
                isSuccess: result.isSuccess,
                error: result.error,
                errorMessage: result.errorText
            };
        default:
            return state;
    }
};

export default PasswordResetRequestReducer;