import State from './state';
import * as SigninActions from '../actions/signin';
import { ErrorReason } from '../misc/models';

/**
 * The default initial state
 */
export const SigninInitialState: State.Signin = {
    errorReason: ErrorReason.LoginNoError,
    errorMessage: '',
    acceptedPrivacyPolicy: false,
    privacyPolicyClientId: 0,
    signInSuccess: false,
    redirectUrl: '',
    privacyPolicyToken: null,
    userName: ''
};

/**
 * Reducer for sign in
 */
const SigninReducer = (state: State.Signin = SigninInitialState, action: SigninActions.Actions): State.Signin => {
    switch (action.type) {
        case SigninActions.SignInClearFailureType:
            return { ...state, errorMessage: '' };
        case SigninActions.SigninFailureType:
            return { ...state, errorReason: action.payload.errorReason, errorMessage: action.payload.errorMessage, signInSuccess: false };
        case SigninActions.SignInSuccessType:
            return {
                ...state,
                acceptedPrivacyPolicy: action.payload.signinResult.acceptedPrivacyPolicy,
                privacyPolicyClientId: action.payload.signinResult.privacyPolicyClientId,
                signInSuccess: true,
                redirectUrl: action.payload.signinResult.redirectUrl,
                privacyPolicyToken: action.payload.privacyPolicyToken
            };
        default:
            return state;
    }
};

export default SigninReducer;