import { EmptyObject } from '../../misc/common';
import * as React from 'react';
import { CmsText } from '../../misc/cms-text';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import State from '../../reducers/state';

/**
 * The route parameters for the parent routing page. Must match what is in App.tsx path.
 * Needs to be used within child components.
 */
export type LoginLinkExpiredRouteProps = {
    token: string
};

/**
 * Props for the form, loaded from state
 */
export type LoginLinkExpiredStateProps = {
    componentText: any,
    sendedSuccessful: boolean
};

/**
 * Dispatch props for the form
 */
export type LoginLinkExpiredDispatchProps = {
    sendNewInvitationLink: (token: string) => ThunkAction<Promise<void>, State.All, unknown, AnyAction>
};

/**
 * All combined props used for the form
 */
export type LoginLinkExpiredProps = LoginLinkExpiredDispatchProps & 
                                    LoginLinkExpiredStateProps & 
                                    LoginLinkExpiredRouteProps;

/**
 * The component for sending new invitation link
 */
class LoginLinkExpired extends React.Component<LoginLinkExpiredProps, EmptyObject> {
    constructor(props: LoginLinkExpiredProps) {
        super(props);
        this.onAccepted = this.onAccepted.bind(this);
    }

    onAccepted() {
        this.props.sendNewInvitationLink(this.props.token);
    }

    render() {
        let cmsText: CmsText = new CmsText(this.props.componentText, 'Login link expired');

        return (
            <div className="content">
                <div hidden={this.props.sendedSuccessful}>
                    <div className="panel-info">
                        <h1 className="form-control-static signinpanel-greeting" >{cmsText.get('welcomeMessage', 'welcome')}
                            <span className="signinpanel-greeting-accent">{cmsText.get('instrumentName', 'KornFerry Assessment Systems')}</span>
                        </h1>
                        <h2 className="signinpanel-statement">
                            {cmsText.get('expirationText', 'The link you have used has expired. Click below to send a new link to your registered email address.')}
                        </h2>
                        
                    </div>
                    <div className="form-button">
                        <button className="accept" type="submit" onClick={this.onAccepted}>{cmsText.get('sendButton', 'Send new link')}</button>
                    </div>
                </div>
                <div className="panel-info" hidden={!this.props.sendedSuccessful}>
                    <h2 className="signinpanel-statement">
                        {cmsText.get('emailSended', 'A new link has been emailed to your registered email address.')}
                    </h2>
                    <h2 className="signinpanel-statement">
                        {cmsText.get('closePage', 'Please close this page.')}
                    </h2>
                </div>
            </div>
        );
    }
}

export default LoginLinkExpired;