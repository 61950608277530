import { EmptyObject } from '../../misc/common';
import * as React from 'react';

export type ErrorComponentStateProps = {
    /**
     * Text object for the component   
     */
    componentText: any,

    /**
     * The error message
     */
    errorMessage: string
};

/**
 * All combined props
 */
export type ErrorComponentProps = ErrorComponentStateProps;

/**
 * The error component
 */
class ErrorComponent extends React.Component<ErrorComponentProps, EmptyObject> {
    render() {
        return (
            <div className="content">
                <div className="panel-info">
                    {this.props.errorMessage}
                </div>
            </div>
        );
    }
}

export default ErrorComponent;