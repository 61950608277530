import { ThunkAction } from 'redux-thunk';
import * as Models from './models';
import State from '../reducers/state';
import { CmsText } from './cms-text';
import { AnyAction } from 'redux';
/**
 * Empty object to pass instead of any to make it clearer and to stop tslint complaining.
 */
export interface EmptyObject { }

/**
 * Type alias to represent a JSX element which is null and therefore does not need rendering.
 * 
 * This is required in components that return either an element or nothing depending on some kind of condition. 
 * The problem with that though is that the render function of the component will then return the type 
 * JSX.Element | null, and when you try to connect that component to a Redux container component it will fail 
 * type checking because it will state that the element could potentially be null.
 * 
 * To fix this use some TypeScript magic with the post-fix operator ! which says to remove the optional part 
 * of the type to leave what we really want.
 * 
 * @example
 * render() {
 *  if (!condition) {
 *      return NoJsxElement;
 *  }
 *  else {
 *      return <div></div>;
 *  }
 * }
 */
export const NoJsxElement = null!;

/**
 * Redirects the user to the Url, likely to take them outside of the application
 * @param redirectUrl The absolute url to redirect to
 */
export function ExternalRedirect(redirectUrl: string) {
    window.location.href = redirectUrl;
}

/* * A nullable JSX.Element that can either return a JSX.Element or null. The null return should use NoJsxElement.
 */
export type NullableElement = JSX.Element | null;

/**
 * Gets the text for an error reason, from the CMS text stored in text.
 * This needs to be dispatched and will return immediatley with the string (e.g. let text = ErrorReasonTextAction(reason));
 * @param errorReason The error reason to get the message for
 */
export function ErrorReasonTextAction(errorReason: Models.ErrorReason): ThunkAction<string, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        if (errorReason === Models.ErrorReason.LoginNoError) {
            return '';
        }

        let text = getState().signinbox.alltext.errors[Models.ErrorReason[errorReason]];
        let cms = new CmsText(text, 'signinFailure');
        let errorText = cms.get('Message', '');
        return errorText;
    };
}

/**
 * Check if a string is either undefined, null or empty
 */
export function IsStringNullOrEmpty(str: string | null): boolean {
    return str === undefined || str === null || str.length < 1;
}