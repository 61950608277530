import { EmptyObject } from './misc/common';
import PasswordResetRequestPage from './pages/password-reset-request';
import ErrorPage from './pages/error-page';
import SigninPage from './pages/signin';
import TokenPage from './pages/token';
import PasswordResetPage from './pages/password-reset';
import OpenInvitationPage from './pages/open-invitation';
import PasswordExpired from './pages/password-expired';
import SetPassword from './pages/set-password';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect, MapDispatchToProps } from 'react-redux';
import State from './reducers/state';
import FontResize from './services/font-resize.service';

export type AppProps = {
  brandedLogoUrl: string
};

const mapStateToProps = (state: State.All) => ({
  brandedLogoUrl: state.branding.logoUrl
});

const mapDispatchToProps: MapDispatchToProps<EmptyObject, EmptyObject> = () => ({});

/**
 * The main signin app.
 */
class App extends React.Component<AppProps, EmptyObject> {
  private fontResize: FontResize = new FontResize();

  render() {
    /* SUBSCRIBE font-size changes */
    this.fontResize.startDetect();

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/:brandingName?/:isPreview?" element={<SigninPage/>} />
          <Route path="/open-invitation/:id/:ppaccepted?" element={<OpenInvitationPage/>} />
          <Route path="/token/:token" element={<TokenPage/>} />
          <Route path="/password-reset/:token?" element={<PasswordResetPage/>} />
          <Route path="/password-reset-request" element={<PasswordResetRequestPage/>} />
          <Route path="/password-expired" element={<PasswordExpired/>} />
          <Route path="/set-password" element={<SetPassword/>} />
          <Route path="/error/:initialError/:brandingName?" element={<ErrorPage/>} />
        </Routes>
      </BrowserRouter>
    );
  }

  componentWillUnmount() {
      /* UNSUBSCRIBE font-size changes */
      this.fontResize.stopDetect();
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
