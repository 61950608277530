/**
 * Generic redux action
 */
export type Action<T, P> = {
    readonly type: T;
    readonly payload: P;
};

/**
 * Creates an Action
 * @param type The type of action, should be a string literal
 * @param payload The expected payload to provide the action. Either a simple type or typed object.
 */
export function createAction<T extends string, P>(type: T, payload: P): Action<T, P> {
    return { type, payload };
}