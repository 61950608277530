import * as React from 'react';
import CmsDocumentTitle from '../containers/cms-document-title';
import PasswordResetRequestContainer from '../containers/password-reset-request';
import ContentWrapper from '../components/contentWrapper';

export default function PasswordResetRequestPage() {
    return (
        <ContentWrapper>
            <CmsDocumentTitle titlePath="passwordResetRequest.documentTitle">
                <PasswordResetRequestContainer />
            </CmsDocumentTitle>
        </ContentWrapper>
    );
}