import { ThunkAction } from 'redux-thunk';
import { Action, createAction } from './action';
import * as SendService from '../services/login-link-expired-service';
import State from '../reducers/state';
import { logError } from '../misc/error';
import { AnyAction } from 'redux';

// Successful  send invitation action
export const SendSuccessType = 'SEND_SUCCESS';
export type SendSuccessAction = Action<typeof SendSuccessType, boolean>;

export function sendSuccess(success: boolean): SendSuccessAction {
    return createAction(SendSuccessType, success);
}

// Send new invitation link. Returns a Promise<void> so that redux-form knows when send is in-progress
export function SendNewInvitationLink(token: string): ThunkAction<Promise<void>, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            SendService.SendNewInvitationLink(token)
                .then(r => {
                    if (r.status === 200) {
                        dispatch(sendSuccess(true));
                    } else {
                        logError('error sending new invitation link');
                    }

                    resolve();
                })
                .catch(r => {
                    logError('error sending new invitation link', r);
                    resolve();
                });
        });
    };
}

export type Actions = SendSuccessAction;