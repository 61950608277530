import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import State from '../reducers/state';
import * as Model from '../misc/models';
import { Helmet } from 'react-helmet';

const rtl: string = 'rtl';
const ltr: string = 'ltr';

/**
 * State props for the Wrapper IntlProvider component
 */
export type IntlProviderWrapperProps = {
  /**
   * The current site language to change the formatting and messages for
   */
  language: Model.LanguageModel;

  /**
   * Child components to render
   */
  children?: React.ReactNode
};

const mapStateToProps = (state: State.All) => ({
  language: state.signinbox.language
});

/**
 * A Wrapper around IntlProvider to bind the language to the Redux state language component,
 * when the site language is changed the provider is updated as well.
 * @param props The language and child components to render
 */

const IntlProviderWrapper = (props: IntlProviderWrapperProps) => (
  <div className={props.language.isRightToLeft ? rtl : ''} dir={props.language.isRightToLeft ? rtl : ltr}>
    <IntlProvider locale={props.language.code} key={props.language.code} defaultLocale="en">
      {props.children}
    </IntlProvider>
    {/* @ts-ignore */}
    <Helmet>
      <html lang={props.language.code} />
    </Helmet>
  </div>
);

export default connect(
  mapStateToProps
)(IntlProviderWrapper);