import React from 'react';
import { CmsText } from '../../misc/cms-text';

/**
 * The properties for SigninBox set from state
 */
export type SigninBoxStateProps = {
    languageId: number,
    availableLanguages: Array<any>,
    fetchingText: boolean,
    componentText: any
};

export interface SigninBoxOwnProps {
    children?: React.ReactNode;
}

/**
 * The properties for SigninBox that are used with state dispatch
 */
export type SigninBoxDispatchProps = {
    changeLanguage: (id: number, byUserAction: boolean) => void
};

type SigninBoxOwnState = {
    languageIdSelected: number
    selectedLanguage: any;
};

/**
 * All properties for SigninBox
 */
export type SigninBoxProps = SigninBoxStateProps & SigninBoxDispatchProps & SigninBoxOwnProps;

/**
 * The sign in box component. Provide children as the content of the box
 */
class SigninBox extends React.Component<SigninBoxProps, SigninBoxOwnState> {
    private cmsText: CmsText;
    constructor(props: SigninBoxProps) {
        super(props);
        this.state = { languageIdSelected: props.languageId, selectedLanguage: null };
        this.cmsText = new CmsText(this.props.componentText, 'Signin');
    }

    private getSelectedLanguage(): any {
        return this.props.availableLanguages.find(language => language.id === this.state.languageIdSelected);
    }

    handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedLanguage = this.getSelectedLanguage(); // Use the helper method
        this.setState({ languageIdSelected: parseInt(event.currentTarget.value), selectedLanguage });
    }

    handleGoButtonClick = () => {
        const announcementText = this.cmsText.get('accessibilityLanguageChanged', 'Language changed to');
        this.props.changeLanguage(this.state.languageIdSelected, true);
        const selectedLanguage = this.getSelectedLanguage();

        // Announce the language change on the "Go" button click
        const liveRegion = document.getElementById('liveRegion');
        if (liveRegion) {
            liveRegion.innerText = `${announcementText} ${selectedLanguage ? selectedLanguage.nativeName : ''}`;
        }
    }

    public render() {

        return (
            <div>
                <div className="wrapper">
                    <div className="signinpanel-language dropdown">
                        <select
                            className="form-control dropdown-toggle form-select"
                            onChange={this.handleLanguageChange}
                            value={this.state.languageIdSelected}
                            aria-label={this.cmsText.get('language', 'Language')}
                        >
                            {
                                !this.props.availableLanguages || this.props.availableLanguages.map(
                                    (language: any) => <option key={language.id} value={language.id}>{language.nativeName}</option>
                                )
                            }
                        </select>
                        <button
                            type="button"
                            className="accept language-go-button"
                            onClick={this.handleGoButtonClick}
                            aria-label="Go"
                        >
                            {this.cmsText.get('go', 'Go')}
                        </button>
                
                        <div
                            id="liveRegion"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                             {` ${this.getSelectedLanguage() ? this.getSelectedLanguage().nativeName : ''} Selected`}
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default SigninBox;