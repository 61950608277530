import * as React from 'react';
import { connect } from 'react-redux';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import SetPassword from '../components/set-password/set-password';

const mapStateToProps = (state: State.All) => ({
    componentText: state.signinbox.alltext.passwordSet
});

export type SetPasswordStateProps = {
    componentText: any
};

export type SetPasswordProps = SetPasswordStateProps;

class SetPasswordContainer extends React.Component<SetPasswordProps> {
    render() {
        return (
            <SigninBoxContainer>
                <SetPassword {...this.props} />
            </SigninBoxContainer>
        );
    }
}

export default connect(mapStateToProps)(SetPasswordContainer);
