import { EmptyObject } from '../misc/common';
import { SigninRouteProps } from '../components/signin/signin';
import ErrorComponent, { ErrorComponentProps } from '../components/error/error';
import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import * as SigninActions from '../actions/signin';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import * as Models from '../misc/models';
import { Dispatch } from 'redux';

const mapStateToProps = (state: State.All) => ({
    componentText: state.signinbox.alltext.signin,
    errorMessage: state.signin.errorMessage
});

const mapDispatchToProps: MapDispatchToProps<ErrorContainerDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    raiseFailure: (reason: Models.ErrorReason) => {
        dispatch(SigninActions.signinFailureFromReason(reason));
    }
});

type ErrorContainerDispatchProps = {
    raiseFailure: (reason: Models.ErrorReason) => void;
};

/**
 * Container for the error page
 * Used in the router, and uses the sign in box as a parent
 */
class ErrorContainer extends React.Component<ErrorComponentProps & ErrorContainerDispatchProps & SigninRouteProps, EmptyObject> implements React.ComponentLifecycle<ErrorComponentProps, EmptyObject> {

    /**
     * Check if we have an initial error to display, passed from the route.
     * If we do, we need to display it the same as a sign in failure.
     * We only want to do this on initial component load.
     */
    componentDidMount() {
        if (this.props.initialError !== undefined) {
            let val = parseInt(this.props.initialError);

            // Check the property is actually a number and in our reason enum. Prevents url tampering.
            if (!isNaN(val) && val in Models.ErrorReason) {
                this.props.raiseFailure(val);
            }
        }
    }

    render() {
        return (
            <div>
                <SigninBoxContainer>
                    <ErrorComponent {...this.props} />
                </SigninBoxContainer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorContainer);;