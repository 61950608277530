import { AuthenticationResult, ExpiredProjectLoginMethod } from '../misc/models';
import { EmptyObject } from '../misc/common';
import * as React from 'react';
import * as SigninService from '../services/signin-service';
import { connect, MapDispatchToProps } from 'react-redux';
import PrivacyPolicy, {  } from '../components/privacy-policy/privacy-policy';
import * as Models from '../misc/models';
import { ExternalRedirect } from '../misc/common';
import { AppService } from '../services/app-service';
import { Navigate } from 'react-router-dom';
import * as SigninBoxActions from '../actions/signin-box';
import * as BrandingActions from '../actions/branding';
import { Dispatch } from 'redux';
import ContentWrapper from '../components/contentWrapper';
import { Loading } from '../components/loading/loading';
import LoginLinkExpired from '../pages/login-link-expired';
import ProjectExpired from '../pages/project-expired';
import SigninPage from '../pages/signin';

/**
 * Props for the AuthenticatePage, values passed in via React Router
 */
export type TokenRouteOwnProps = {
    /**
     * The token string passed in from the KF signin app
     */
    token: string,
    children?: React.ReactNode
};

/**
 * Enum constants to define the different states the AuthenticatePage component can be in.
 */
enum AuthenticationState {
    /** The component is current sending/awaiting the authorization request */
    Authorizing,

    /** Authorization succeeded and an API key was returned */
    ResultReceived,

    /** Authorization failed and an error was returned */
    ErrorReturned
}

type TokenContainerState = {
    /**
     * The current local state this component is in as part of the process flow
     */
    currentState: AuthenticationState;

    /**
     * The authentication result
     */
    result?: AuthenticationResult;

    /**
     * The error response returned by the server if unsuccessful.
     */
    errorResponse?: AppService.ResponseError,
};

/**
 * The properties for SigninBox that are used with state dispatch
 */
export type TokenDispatchProps = {
    changeLanguage: (id: number) => void,
    updateBranding: (branding: Models.BrandingResult) => void
};

const mapDispatchToProps: MapDispatchToProps<TokenDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    changeLanguage: (id: number) => {
        dispatch(SigninBoxActions.changeLanguage(id));
    },
    updateBranding: (branding: Models.BrandingResult) => {
        dispatch(BrandingActions.finishGetBranding(branding, false));
    }
});

/**
 * All properties for Token
 */
export type TokenProps = TokenRouteOwnProps & TokenDispatchProps;

/**
 * Token container class for verifying a direct login token and then accepting the privacy policy if required
 */
class TokenContainer extends React.Component<TokenProps, TokenContainerState> {
    constructor(props: TokenProps) {
        super(props);
        this.state = {
            currentState: AuthenticationState.Authorizing
        };
    }

    /**
     * Lifecycle event that occurs when the component has finished rendering.
     * 
     * After rendering the initial loading splash screen, authenticate the user using the provided token and create a new session to continue with
     * using the dashboard.
     */
    componentDidMount() {
        if (this.state.currentState === AuthenticationState.Authorizing) {
            this.authorize();
        }
    }

    /**
     * Lifecycle event that occurs when the component has updated state.
     * @param prevProps The previous props object
     * @param prevState The previous state object
     * @param prevContext The previous context
     */
    /* tslint:disable-next-line:no-any */
    componentDidUpdate(prevProps: Readonly<TokenRouteOwnProps>, prevState: Readonly<TokenContainerState>, prevContext: any) {
        if (this.state.currentState === AuthenticationState.ResultReceived) {
            if (this.state.result!.signinResult.isSuccessful && this.state.result && this.state.result.signinResult.acceptedPrivacyPolicy) {
                ExternalRedirect(this.state.result!.signinResult.redirectUrl);
            }
        }
    }

    render() {
        let signinResult = new Models.SigninResult();

        if (this.state.result) {
            Object.assign(signinResult, this.state.result.signinResult);
        }

        if (signinResult.isLoginLinkExpired()) {
            return LoginLinkExpired(this.props.token, signinResult.languageId);
        }

        if (signinResult.allProjectsExpiredAndIncomplete()) {
            return ProjectExpired(ExpiredProjectLoginMethod.InvitationLink);
        }

        if(signinResult.isLoginIntegration()){
            return <SigninPage userName={signinResult.userName} />;
        }

        if (this.state.currentState === AuthenticationState.ResultReceived && this.state.result && !this.state.result.signinResult.isSuccessful) {
            return <Navigate replace to={`/error/${signinResult.errorReason}`}  />;
        }

        if (this.state.currentState === AuthenticationState.ErrorReturned) {
            return <Navigate replace to={`/error/${signinResult.errorReason}`}  />;
        }

        if (this.state.currentState === AuthenticationState.ResultReceived && this.state.result && this.state.result.signinResult.isSuccessful && !this.state.result.signinResult.acceptedPrivacyPolicy) {
            return (
                <ContentWrapper>
                    <PrivacyPolicy 
                        internalRedirectUrl={this.state.result.signinResult.redirectUrl} 
                        internalErrorRedirectUrl={'/error/34'} 
                        clientId={this.state.result.signinResult.privacyPolicyClientId} 
                        token={this.state.result.privacyPolicyToken} />
                </ContentWrapper>
            );
        }

        return <Loading />;
    }

    private authorize() {
        SigninService.submitToken(this.props.token)
            .then(result => {
                if (result.branding) {
                    this.props.updateBranding(result.branding);
                }

                let signinResult = new Models.SigninResult();
                Object.assign(signinResult, result.signinResult);

                // Change the site language to the candidate preferred language for the privacy policy page or for login-error.
                if (signinResult.isChangingToCandidateLanguageNeeded()) {
                    this.props.changeLanguage(signinResult.languageId);
                }

                this.setState({
                    currentState: AuthenticationState.ResultReceived,
                    result: result as AuthenticationResult
                });
            })
            .catch((error: AppService.ResponseError) => {
                this.setState({
                    currentState: AuthenticationState.ErrorReturned,
                    errorResponse: error
                });
            });
    }
}

export default connect<EmptyObject, TokenDispatchProps, TokenRouteOwnProps>(
    null,
    mapDispatchToProps
)(TokenContainer);