import { ThunkAction } from 'redux-thunk';
import State from './state';
import * as BrandingActions from '../actions/branding';
import * as Models from '../misc/models';

/**
 * The default initial state
 */
export const BrandingInitialState: State.Branding = {
    styles: '',
    loadingBrandingUrl: false,
    logoUrl: '',
    name: undefined,
    hasSignInManifest: false,
    isPreview: false,
    isLoaded: false,
    isLoading: false
};

/**
 * Reducer for Branding
 */
const BrandingReducer = (state: State.Branding = BrandingInitialState, action: BrandingActions.Actions): State.Branding => {
    switch (action.type) {
        case BrandingActions.GetBrandingStartType:
            return {
                ...state,
                loadingBrandingUrl: true,
                isLoading: true,
                isLoaded: false
            };
        case BrandingActions.GetBrandingFinishType:
            return {
                ...state,
                loadingBrandingUrl: false,
                styles: action.payload.styles,
                logoUrl: action.payload.logoUrl,
                name: action.payload.name,
                hasSignInManifest: action.payload.hasSignInManifest,
                isLoaded: true,
                isLoading: false
            };
        case BrandingActions.SetPreviewModeType:
            return {
                ...state,
                isPreview: action.payload
            };
        default:
            return state;
    }
};

export default BrandingReducer;