import State from './state';
import * as Models from '../misc/models';
import * as OpenInvitationActions from '../actions/open-invitation';

/**
 * The default initial state
 */
export const OpenInvitationInitialState: State.OpenInvitation = {
    hasSettings: false,
    isActive: false,
    privacyPolicyRequired: false,
    privacyPolicyClintId: 0,
    showCaptcha: false,
    id: '',
    hasSuccessMessage: false,
    errorMessage: '',
    error: 0,
    captchaLanguageCulture: 'en',
    stage: Models.OpenInvitationStage.None,
    email: '',
    isProjectExpired: false,
    isParticipantEnteredPassword: false,
};

/**
 * Reducer for open invitation
 */
const OpenInvitationReducer = (state: State.OpenInvitation = OpenInvitationInitialState, action: OpenInvitationActions.Actions): State.OpenInvitation => {
    let result;
    switch (action.type) {
        case OpenInvitationActions.GetSettingsResultType:
            result = action.payload;
            return {
                ...state,
                isActive: result.isActive,
                isProjectExpired: result.isProjectExpired || false,
                privacyPolicyRequired: result.privacyPolicyRequired,
                privacyPolicyClintId: result.privacyPolicyClientId,
                isParticipantEnteredPassword: result.isParticipantEnteredPassword,
                showCaptcha: result.showCaptcha,
                id: result.id,
                hasSettings: true,
                stage: result.stage
            };
        case OpenInvitationActions.AcceptPPType:
            return {
                ...state,
                privacyPolicyRequired: false
            };
        case OpenInvitationActions.RegisterResultType:
            result = action.payload;
            return {
                ...state,
                error: result.error,
                errorMessage: result.errorText,
                hasSuccessMessage: result.isSuccessMessage
            };
        case OpenInvitationActions.SsoEmailGateResultType:
            result = action.payload;
            return {
                ...state,
                error: result.error,
                errorMessage: result.errorText,
                stage: result.stage,
                email: result.email,
                showCaptcha: false
            };
        default:
            return state;
    }
};

export default OpenInvitationReducer;