import { CmsManifestMetaData } from '../misc/cms-manifest-metadata';
import * as Models from '../misc/models';
import { AppService } from './app-service';

/**
 * Submit a request to get an SSO redirect url
 */
export function submitSsoRedirectUrlRequest(id: string, email: string, ssoProviderId: number, privacyPolicyCustomCheckboxAccepted: boolean | null, manifestMetaData: CmsManifestMetaData | null): Promise<Models.SsoRedirectResult> {
    let body = JSON.stringify({ id, email, ssoProviderId, privacyPolicyCustomCheckboxAccepted, manifest: manifestMetaData });
    return AppService.PostThenToObject<Models.SsoRedirectResult>('openinvitation/ssoredirect', body, Models.SsoRedirectResult);
}