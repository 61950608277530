import * as Models from '../misc/models';
import { AppService } from './app-service';

/**
 * Password reset service (to handle requests and actual reset)
 */
/**
 * Submit a request for a password reset
 */
export function SubmitResetRequest(username: string): Promise<string> {
    let body = JSON.stringify({ username: username });
    return AppService.PostThenTextResult('passwordreset/resetrequest', body);
}

/**
 * Validate a password reset token
 * @param token The token to validate
 */
export function ValidateResetToken(token: string): Promise<Models.ValidateResetTokenResult> {
    let body = JSON.stringify({ token: token });
    return AppService.PostThenToObject('passwordreset/validatetoken', body, Models.ValidateResetTokenResult);
}

/**
 * Submit a password reset
 * @param token The token used to validate the password reset
 * @param password The new password
 * @param verifyPassword The confirmation of the new password
 */
export function SubmitPasswordReset(token: string, password: string, verifyPassword: string): Promise<Models.ResetResult> {
    let body = JSON.stringify({ token, password, verifyPassword });
    return AppService.PostThenToObject('passwordreset/reset', body, Models.ResetResult);
}