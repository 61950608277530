
import * as Models from '../misc/models';

const SESSION_BRANDING_KEY = 'KF_BRANDING';

const _getBranding = (): Models.BrandingResult => JSON.parse(sessionStorage.getItem(SESSION_BRANDING_KEY)!);

export const storeBranding = (branding: Models.BrandingResult) => sessionStorage.setItem(SESSION_BRANDING_KEY, JSON.stringify(branding));

export const hasBranding = (): boolean => {
    let brandingAvailable = false;
    try {
        const branding = _getBranding();
        if (Object.keys(branding).length > 0) {
            brandingAvailable = true;
        }
    } catch (e) {
        clearBrandingSession();
    }
    return brandingAvailable;
};

export const getBranding = (): Models.BrandingResult => _getBranding();

export const clearBrandingSession = (): void => sessionStorage.removeItem(SESSION_BRANDING_KEY);