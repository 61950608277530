import * as React from 'react';
import { connect } from 'react-redux';
import State from '../../reducers/state';
import { hasBranding, getBranding } from '../../misc/session';
const Style = require('react-style-tag').default;

/**
 * State props for the branding component
 */

export type BrandingWrapperProps = {
    styles: string;
    children?: React.ReactNode
};
const mapStateToProps = (state: State.All) => ({
    styles: state.branding.styles
});

const BrandingWrapper = (props: BrandingWrapperProps) => (
    <div>
        <style>
            {hasBranding() && getBranding().styles || props.styles}
        </style>
    </div>
);

export default connect(
    mapStateToProps
)(BrandingWrapper);