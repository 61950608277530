import * as React from 'react';
import { connect } from 'react-redux';
import Footer, { FooterStateProps } from '../components/footer/footer';
import { EmptyObject } from '../misc/common';
import State from '../reducers/state';

const mapStateToProps = (state: State.All) => ({
    componentText: state.signinbox.alltext.footer
});

/**
 * Container for the password reset request page.
 */
class FooterContainer extends React.Component<FooterStateProps, EmptyObject> {
    render() {
        return (
            <Footer {...this.props} />
        );
    }
}

export default connect(
    mapStateToProps
)(FooterContainer);