import * as React from 'react';
import { connect } from 'react-redux';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import ProjectExpired from '../components/project-expired/project-expired';
import { ExpiredProjectLoginMethod } from '../misc/models';

const mapStateToProps = (state: State.All) => ({
    componentText: state.signinbox.alltext.projectExpired
});

/**
 * Props for the form, loaded from state
 */
export type ProjectExpiredStateProps = {
    componentText: any
};

/**
 * The route parameters for the parent routing page. Must match what is in App.tsx path.
 * Needs to be used within child components.
 */
export type ProjectExpiredRouteProps = {
    method: ExpiredProjectLoginMethod
};

export type ProjectExpiredProps = ProjectExpiredStateProps & ProjectExpiredRouteProps;

/**
 * Container for showing expired project
 * Uses the sign in box as a parent
 */
class ProjectExpiredContainer extends React.Component<ProjectExpiredProps> {
    render() {
        return (
            <div>
                <SigninBoxContainer>
                    <ProjectExpired {...this.props} />
                </SigninBoxContainer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps
)(ProjectExpiredContainer);