/**
 * Data models
 */

/**
 * The result of a sign in, where credentials are verified
 */
export class SigninResult {
    isSuccessful!: boolean;
    errorReason!: ErrorReason;
    action!: string;
    redirectUrl!: string;
    acceptedPrivacyPolicy!: boolean;
    privacyPolicyClientId!: number;
    languageId!: number;
    userName?: string;

    /**
     * Is invitation link expired (Expiration date in token or all Candidate's projects expired and incomplete).
     */
    isLoginLinkExpired(): boolean {
        return this.errorReason === ErrorReason.LoginLinkExpired;
    }

    /**
     * Are all Candidate's projects expired and incomplete (When login by for username and password).
     */
    allProjectsExpiredAndIncomplete(): boolean {
        return this.errorReason === ErrorReason.ProjectExpired;
    }

    /**
     * Do we need to change the site language to the candidate preferred language for the privacy policy page or for login-error.
     */
    isChangingToCandidateLanguageNeeded(): boolean {
        return (this.isSuccessful && !this.acceptedPrivacyPolicy) || (!this.isSuccessful && (this.allProjectsExpiredAndIncomplete() || this.isLoginLinkExpired()));
    }

    /**
     * Login intergration error 
     */
    isLoginIntegration(): boolean {
        return this.errorReason === ErrorReason.LoginIntegration;
    }
}

/**
 * The login error
 */
export class SigninError {
    errorReason!: ErrorReason;
    errorMessage!: string;
}

/**
 * A authentication result that contains the result of signin (verification of credentials) a token to be used for privacy policy.
 * Used in both the username/password and token workflows
 */
export class AuthenticationResult {
    signinResult!: SigninResult;
    privacyPolicyToken!: string | null;
}

/**
 * The result of a sign in which also returns branding details (for token workflow)
 */
export class AuthenticationResultWithBranding extends AuthenticationResult {
    branding!: BrandingResult;
}

/**
 * Branding  object
 */
export class BrandingResult {
    version!: string;
    errorText!: string;
    styles!: string;
    name!: string;
    logoUrl!: string;
    hasSignInManifest!: boolean;
}

export class ValidateResetTokenResult {
    username!: string;
    token!: string;
    error!: ErrorReason;
    tokenIsValid!: boolean;
    brandingStyles!: BrandingResult;
}

export class ResetResult {
    error!: ErrorReason;
    /**
     * Error text is retrieved by the client
     */
    errorText!: string;
    tokenIsValid!: boolean;
    isSuccess!: boolean;
    redirectUrl!: string;
}

export class OpenInvitationSettings {
    isActive!: boolean;
    isProjectExpired!: boolean;
    privacyPolicyRequired!: boolean;
    privacyPolicyClientId!: number;
    languageId!: number;
    showCaptcha!: boolean;
    id!: string;
    brandingStyles!: BrandingResult;
    stage!: OpenInvitationStage;
    isParticipantEnteredPassword!: boolean;
}

export class LanguageModel {
    id!: number;
    googleCode!: string;
    code!: string;
    isRightToLeft!: boolean;
}

export class PrivacyModel {
    customPrivacyHeader!: string;
    customPrivacyStatement!: string;
    customCheckboxStatement!: string | null;
    customTransferringDataStatement!: string | null;
    customAcceptButtonStatement!: string | null;
    customCancelButtonStatement!: string | null;
}

export class OpenInvitationRegisterResult {
    /**
     * If registration was successful
     */
    isSuccess!: boolean;
    /**
     * If a redirect now needs to happen
     */
    isSuccessRedirect!: boolean;
    /**
     * Whether a message needs to be shown to the user
     */
    isSuccessMessage!: boolean;
    /**
     * The error/message reason
     */
    error!: ErrorReason;
    /**
     * Text representation of the error. Retrieved by the client
     */
    errorText!: string;
    /**
     * The redirect url if required
     */
    redirectUrl!: string;
}

export class SsoEmailGateResult {
    /**
     * This will be present if the user is forced to use a specific SSO Provider.
     * If present, the redirection url should be retrieved and used automatically
     */
    enforcedSsoProviderId!: number;
    /**
     * The next stage to present the user (form or sso selection)
     */
    stage!: OpenInvitationStage;
    /**
     * The error if one happened
     */
    error!: ErrorReason;
    /**
     * Error text
     */
    errorText!: string;
    /**
     * The email address of the request
     */
    email!: string;
}

export class SsoRedirectResult {
    /**
     * The external redirect url
     */
    redirectUrl!: string;
}

/**
 * Possible error reasons returned by a sign in result.
 * Must match TQLogic.LoginError
 */
export enum ErrorReason {
    LoginNoError = 0,
    LoginSelfService = 1,
    LoginPasswordChange = 2,
    LoginPasswordConfirm = 3,
    LoginPasswordMatch = 4,
    LoginPasswordTooLong = 5,
    LoginPasswordSame = 6,
    LoginCredential = 7,
    LoginInactiveClient = 8,
    LoginInvalidClient = 9,
    LoginExpired = 10,
    LoginSelfServiceFailed = 11,
    LoginSelfServiceEmailed = 12,
    LoginSelfServiceRegistered = 13,
    LoginIntegration = 14,
    LoginAttemptsNeedToWaitTimeLocked = 15,
    LoginAttemptsFirstWarning = 16,
    LoginAttemptsSecondWarning = 17,
    LoginAttemptsThirdWarning = 18,
    LoginAttemptsForthWarning = 19,
    LoginAttemptsFithWarning = 20,
    LoginAttemptsSixthWarning = 21,
    LoginAttemptsLockOut = 22,
    LoginAccessDenied = 23,
    LoginPasswordResetEmailSent = 24,
    LoginPasswordResetContactAdmin = 25,
    LoginPasswordResetNewPasswordsDoNotMatch = 26,
    LoginPasswordResetNewPasswordValidation = 27,
    LoginPasswordChanged = 28,
    LoginPasswordResetTokenInvalid = 29,
    LoginTandCIncomplete = 30,
    LoginTandCNeededHigherUp = 31,
    LoginPasswordandUsernameValidation = 32,
    LoginSsoRequired = 33,
    LoginSsoFailed = 34,
    LoginLinkExpired = 35,
    ProjectExpired = 36,
    LoginPasswordExpired = 37,
    LoginPasswordNotSet = 38
}

/**
 * Stages of the open invitation workflow
 * Must match KornFerry.Api.Signin.OpenInvitation.OpenInvitationStage
 */
export enum OpenInvitationStage {
    None = 'NONE',
    Form = 'FORM',
    SsoEmailGate = 'SSOEMAILGATE',
    SsoSelection = 'SSOSELECTION'
}

/**
 * How we login to expired project
 */
export enum ExpiredProjectLoginMethod {
    ProjectRegistrationLink = 0,
    InvitationLink = 1,
    UsernameAndPassword = 2
}

/**
 * A generic key value pair object
 */
export class KeyValuePair {
    key: string;
    value: string;

    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
}