import { Action, createAction } from './action';
import { ThunkAction } from 'redux-thunk';
import State from '../reducers/state';
import { CmsService } from '../services/cms-service';
import * as Models from '../misc/models';
import { AppService } from '../services/app-service';
import { AnyAction } from 'redux';

export const LanguageChangeSuccessType = 'LANGUAGE_CHANGE_Success';
export const LanguageChangeErrorType = 'LANGUAGE_CHANGE_Error';
export const FetchingSiteTextType = 'FETCHING_SITE_TEXT';
export type LanguageChangeAction = Action<typeof LanguageChangeSuccessType, State.SigninBox>;
export type LanguageChangeErrorAction = Action<typeof LanguageChangeErrorType, AppService.ResponseError>;
export type FetchingTextAction = Action<typeof FetchingSiteTextType, boolean>;

export function changeLanguageSuccess(newLanguageId: number, alltext: any): LanguageChangeAction {
    // If selected language doesn't exist, use default language instead as this is what the get SiteText
    // request does when an invalid language is requested
    let selectedLanguage = getLanguage(alltext, newLanguageId) || getLanguage(alltext, CmsService.GetDefaultLanguage());

    CmsService.SetSiteLanguage(selectedLanguage.id);

    return createAction(LanguageChangeSuccessType, {
        language: selectedLanguage,
        alltext: alltext,
        isLoaded: true,
        fetchingText: false
    });
}

function getLanguage(alltext: any, languageId: number): Models.LanguageModel {
    return (alltext.allLanguages as Models.LanguageModel[]).filter(l => l.id === languageId)[0];
}

export function changeLanguageError(responseError: AppService.ResponseError) {
    return createAction(LanguageChangeErrorType, responseError);
}

export function fetchingSiteText(): FetchingTextAction {
    return createAction(FetchingSiteTextType, true);
}

export function changeLanguage(newLanguageId?: number | undefined, byUserAction?: boolean | undefined): ThunkAction<void, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        dispatch(fetchingSiteText());

        let state: State.All = getState();
        let brandingName: string | undefined = state.branding.hasSignInManifest ? state.branding.name : undefined;
        let languageChanging: boolean = !!newLanguageId && newLanguageId !== state.signinbox.language.id;
        let languageId: number = newLanguageId ? newLanguageId : state.signinbox.language.id || CmsService.GetSiteLanguage();

        CmsService.GetLoginSiteText(languageId, state.branding.isPreview, brandingName)
            .then(r => {
                dispatch(changeLanguageSuccess(languageId, r));

                if (byUserAction === true) {
                    CmsService.SetLanguageChangesAttributeInSessionStorage(true);
                }
            })
            .catch((error: AppService.ResponseError) => dispatch(changeLanguageError(error)));
    };
}

export type Actions = LanguageChangeAction | LanguageChangeErrorAction | FetchingTextAction;