import * as React from 'react';
import CmsDocumentTitle from '../containers/cms-document-title';
import LoginLinkExpiredContainer from '../containers/login-link-expired';
import ContentWrapper from '../components/contentWrapper';

export default function LoginLinkExpired(token: string, languageId: number): JSX.Element {
    return (
        <ContentWrapper languageId={languageId}>
            <CmsDocumentTitle titlePath="loginLinkExpired.documentTitle">
                <LoginLinkExpiredContainer token={token}/>
            </CmsDocumentTitle>
        </ContentWrapper>
    );
}