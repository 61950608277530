import * as React from 'react';
import { CmsText } from '../../misc/cms-text';
import { useLocation, useNavigate } from 'react-router-dom';

export type SetPasswordProps = {
    componentText: any
};

const SetPassword: React.FC<SetPasswordProps> = ({ componentText }) => {
    const navigate = useNavigate();
    const location = useLocation();  
    // Extract the token from the query parameters
    const searchParams = new URLSearchParams(location.search);
    const tokenParam = searchParams.get('token');
    const token = tokenParam ? encodeURIComponent(tokenParam) : ''; // Encode the token if it's not null

    // Construct the redirect URL for password reset
    const redirectUrl = `/password-reset/${token}`;
    const cmsText = new CmsText(componentText, 'passwordSet');

    const handleContinue = () => {
        navigate(redirectUrl);
    };

    return (
        <div className="content" role="main" aria-labelledby="set-password-title">
            <div className="panel-info">
                <h1 id="set-password-title" className="form-control-static signinpanel-greeting">
                    {cmsText.get('title', 'Set Password')}
                </h1>
                <div className="panel-border" />
                <h2 className="signinpanel-statement" aria-live="polite">
                    {cmsText.get('passwordSetInstructions', 'As this is your first time logging in to the system, you need to create a password. Click \'Continue\' to do so.')}
                </h2>
            </div>
            <div className="form-button">
                <button 
                    className="accept" 
                    onClick={handleContinue}
                    aria-label={cmsText.get('continueButton', 'Continue')}
                    role="button"
                >
                    {cmsText.get('continueButton', 'Continue')}
                </button>
            </div>
        </div>
    );
};

export default SetPassword;
