import * as Models from '../misc/models';
import { AppService } from './app-service';

/**
 * Submit user credentials for verification
 */
export function SubmitSignin(username: string, password: string, languageId: number): Promise<Models.AuthenticationResult> {
    let body = JSON.stringify({ username: username, password: password, languageId: languageId });
    return AppService.PostThenToObject('signin', body, Models.AuthenticationResult);
}

/**
 * Submit user authentication using an encrypted signin token
 * @param token The single-signon token to authenticate with
 */
export function submitToken(token: string): Promise<Models.AuthenticationResultWithBranding> {
    let body = JSON.stringify(token);
    return AppService.PostThenToObject('token', body, Models.AuthenticationResultWithBranding);
}