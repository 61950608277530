import { EmptyObject } from '../misc/common';
import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import SigninBox, { SigninBoxDispatchProps, SigninBoxProps } from '../components/signin-box/signin-box';
import { Dispatch } from 'redux';
import State from '../reducers/state';
import * as SigninBoxActions from '../actions/signin-box';

const mapStateToProps = (state: State.All) => ({
    languageId: state.signinbox.language.id,
    availableLanguages: state.signinbox.alltext.allLanguages,
    fetchingText: state.signinbox.fetchingText,
    componentText: state.signinbox.alltext.signin
});

const mapDispatchToProps: MapDispatchToProps<SigninBoxDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    changeLanguage: (id: number, byUserAction?: boolean | undefined) => {
        dispatch(SigninBoxActions.changeLanguage(id, byUserAction));
    }
});

/**
 * Sign in box container.
 * Used to render a box and language dropdown, to share between actual pages.
 * Use as a parent component and use content as children
 */
class SigninBoxContainer extends React.Component<SigninBoxProps, EmptyObject> {
    render() {
        return (
            <SigninBox {...this.props}>
                {this.props.children}
            </SigninBox>
        );
    }
}

// This means that to use the SigninBoxContainer, no props are needed to be given.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SigninBoxContainer);