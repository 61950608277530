import * as React from 'react';
import CmsDocumentTitle, { CmsDocumentOwnProps, CmsDocumentTitleProps } from '../components/cms-document-title';
import State from '../reducers/state';
import { CmsText } from '../misc/cms-text';
import { connect } from 'react-redux';
import { EmptyObject } from '../misc/common';

const mapStateToProps = (state: State.All, ownProps: CmsDocumentOwnProps) => ({
    text: new CmsText(state.signinbox.alltext, 'DocumentTitle'),
    titlePath: ownProps.titlePath
});

/**
 * Container for the password reset request page.
 */
class CmsDocumentTitleContainer extends React.Component<CmsDocumentTitleProps & CmsDocumentOwnProps, EmptyObject> {
    render() {
        return (<CmsDocumentTitle {...this.props} />);
    }
}

export default connect(
    mapStateToProps,
)(CmsDocumentTitleContainer);