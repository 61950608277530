import { CmsText } from '../../misc/cms-text';
import format from 'string-template';
import parse, { attributesToProps } from 'html-react-parser';

/**
 * Props for the form, loaded from state
 */
export type FooterStateProps = {
    /**
     * Text object for the component   
     */
    componentText: any
};

/**
 * The footer component
 */
export default function Footer(props: FooterStateProps): JSX.Element {
    let cmsText: CmsText = new CmsText(props.componentText, "footer");

    // To add aria-label attribute to the parsed anchor element
    const options = {
      replace(domNode: any) {
        if (domNode.attribs && domNode.name === "a") {
          domNode.attribs["aria-label"] =
            cmsText.getAccessibilityText(
              domNode.children[0]?.data.toLowerCase()
            ) || "";
          const anchorText = domNode.children[0]?.data || "";
          const props = attributesToProps(domNode.attribs);
          return <a {...props}> {anchorText} </a>;
        }
        return domNode;
      },
    };

    return (
        <footer className="footer">
            <div className="brandbar" />
            <div className="footer-bar">
                <div className="copyright">
                    <a><img src="/images/logo-kornferryFooter.svg" alt="Korn Ferry" /></a>
                    <p> {format(cmsText.get('copyRight', ' &copy; Korn Ferry {currentyear} All Rights Reserved.'), { 'currentyear': new Date().getFullYear().toString()})}</p>
                </div>
                <div className="links-right">
                    <nav aria-label="Footer Links">
                        <ul className="list-inline">
                            <li className="list-inline-item">
                              {parse(cmsText.get('terms', 'terms'), options)}
                            </li>
                            <li className="list-inline-item">
                              {parse(cmsText.get('privacy', 'privacy'), options)}
                            </li>                   
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
    );
}
