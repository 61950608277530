import { ThunkAction } from 'redux-thunk';
import State from './state';
import * as Models from '../misc/models';
import * as PasswordResetRequestActions from '../actions/password-reset-request';

/**
 * The default initial state
 */
export const PasswordResetRequestInitialState: State.PasswordResetRequest = {
    result: ''
};

/**
 * Reducer for password reset request
 */
const PasswordResetRequestReducer = (state: State.PasswordResetRequest = PasswordResetRequestInitialState, action: PasswordResetRequestActions.Actions): State.PasswordResetRequest => {
    switch (action.type) {
        case PasswordResetRequestActions.ResetRequestResultType:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export default PasswordResetRequestReducer;