import * as PrivacyPolicyActions from '../../actions/privacy-policy';
import React from 'react';
import State from '../../reducers/state';
import { CmsText } from '../../misc/cms-text';
import {
    connect,
    MapDispatchToProps
} from 'react-redux';
import { EmptyObject, ExternalRedirect, IsStringNullOrEmpty } from '../../misc/common';
import { Navigate } from 'react-router-dom';
import CmsDocumentTitle from '../../containers/cms-document-title';
import { CmsManifestMetaData } from '../../misc/cms-manifest-metadata';
import renderHTML from 'html-react-parser';
import { Dispatch } from 'redux';

type PrivacyPolicyInternalState = {
    accepted: boolean,
    infromationTransferingAccepted: boolean,
    redirect: boolean,
    customCheckboxAccepted: boolean
};

export type PrivacyPolicyStateProps = {
    privacyComponentText: any,
    docMetaData: any,
    languageId: number,
    hasCheckedForCustomPolicy: boolean,
    customPrivacyHeader: string,
    customPrivacyStatement: string,
    customCheckboxStatement: string | null,
    customTransferringDataStatement: string | null,
    customAcceptButtonStatement: string | null,
    customCancelButtonStatement: string | null,
    /**
     * Whether or not there has been an error
     */
    hasError: boolean
};

export type PrivacyPolicyOwnProps = {
    /**
     * Optional internal redirect url for when privacy policy is accepted. Only used if token not provided
     */
    internalRedirectUrl?: string,
    /**
     * The internal redirect url to use when there is an error accepting privacy policy (e.g expired token)
     */
    internalErrorRedirectUrl: string,
    clientId: number,
    /**
     * The single-use token used to submit the response back to the server, if using external redirect workflow
     */
    token?: string | null
};

export type PrivacyPolicyDispatchProps = {
    getCustomPrivacyPolicy: (id: number, languageId: number) => void,
    submitPrivacyPolicyResponseState: (customCheckboxAccepted: boolean | null) => void
    submitPrivacyPolicyResponseServer: (token: string, policyAccepted: boolean, customCheckboxAccepted: boolean | null, manifestMetaData: CmsManifestMetaData | null) => void
};

const mapStateToProps = (state: State.All) => ({
    privacyComponentText: state.signinbox.alltext.privacy,
    docMetaData: state.signinbox.alltext.docMetaData,
    languageId: state.signinbox.language.id,
    hasCheckedForCustomPolicy: state.privacypolicy.hasCheckedForCustomPolicy,
    customPrivacyHeader: state.privacypolicy.customPrivacyHeader,
    customPrivacyStatement: state.privacypolicy.customPrivacyStatement,
    customCheckboxStatement: state.privacypolicy.customCheckboxStatement,
    customTransferringDataStatement: state.privacypolicy.customTransferringDataStatement,
    customAcceptButtonStatement: state.privacypolicy.customAcceptButtonStatement,
    customCancelButtonStatement: state.privacypolicy.customCancelButtonStatement,
    hasError: state.privacypolicy.hasError
});

const mapDispatchToProps: MapDispatchToProps<PrivacyPolicyDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    getCustomPrivacyPolicy: (id: number, languageId: number) => {
        dispatch(PrivacyPolicyActions.getCustomPrivacyPolicy(id, languageId));
    },
    submitPrivacyPolicyResponseState: (customCheckboxAccepted: boolean | null) => {
        dispatch(PrivacyPolicyActions.submitPrivacyPolicyResponseState(customCheckboxAccepted, false));
    },
    submitPrivacyPolicyResponseServer: (token: string, policyAccepted: boolean, customCheckboxAccepted: boolean | null, manifestMetaData: CmsManifestMetaData | null) => {
        dispatch(PrivacyPolicyActions.submitPrivacyPolicyResponseServer(token, policyAccepted, customCheckboxAccepted, manifestMetaData));
    }
});

class PrivacyPolicy extends React.Component<PrivacyPolicyStateProps & PrivacyPolicyOwnProps & PrivacyPolicyDispatchProps, PrivacyPolicyInternalState> {
    readonly defaultAcceptButtonText: string = 'Accept and Continue';
    readonly defaultCancelButtonText: string = 'Cancel and Exit';
    readonly defaultHeaderText: string = 'Confirmation of Global Privacy Policy';
    readonly defaultPrivacyPolicyText: string = 'I confirm my consent to the collection, use and sharing of my information as solely described in the Global Privacy Policy. I also understand and agree that my information may be transferred outside the country that I am based to other locations, which may have different levels of data protection.';
    readonly defaultExplanation: string = 'By selecting “Accept and Continue” you agree to these terms.If you do not agree to these terms, select “Cancel and Exit”.';

    constructor(props: PrivacyPolicyStateProps & PrivacyPolicyOwnProps & PrivacyPolicyDispatchProps) {
        super(props);
        this.state = { accepted: false, infromationTransferingAccepted: false, redirect: false, customCheckboxAccepted: false };
        this.onAccepted = this.onAccepted.bind(this);
        this.hasCustomCheckbox = this.hasCustomCheckbox.bind(this);
    }

    componentDidMount() {
        this.props.getCustomPrivacyPolicy(this.props.clientId, this.props.languageId);
    }

    onAccepted() {
        const customAccepted = this.hasCustomCheckbox() ? this.state.customCheckboxAccepted : null;

        if (this.props.token) {
            this.props.submitPrivacyPolicyResponseServer(this.props.token, this.state.accepted, customAccepted, new CmsManifestMetaData(this.props.docMetaData));
        } else {
            this.props.submitPrivacyPolicyResponseState(customAccepted);
            this.setState({ redirect: true });
        }
    }

    /**
     * Gets whether or not the custom checkbox has been defined
     */
    hasCustomCheckbox(): boolean {
        return !IsStringNullOrEmpty(this.props.customCheckboxStatement);
    }

    hasTransferringStatement(): boolean {
        return !IsStringNullOrEmpty(this.getTransferringStatement());
    }

    getTransferringStatement(): string | null {
        const cmsText: CmsText = new CmsText(this.props.privacyComponentText, 'privacy policy');

        /**
         * this.props.customTransferringDataStatement = '' - allowed
         * For hiding checkbox for Clients, which have not custom login manigfest
         */
        return !(this.props.customTransferringDataStatement === undefined || this.props.customTransferringDataStatement === null)
          ? this.props.customTransferringDataStatement
          : cmsText.get('informationTransferring', '', false);
    }

    getAcceptButtonStatement(): string {
        return !IsStringNullOrEmpty(this.props.customAcceptButtonStatement)
          ? this.props.customAcceptButtonStatement || this.defaultAcceptButtonText
          : new CmsText(this.props.privacyComponentText, 'privacy policy').get('accept', this.defaultAcceptButtonText);
    }

    getCancelButtonStatement(): string {
        return !IsStringNullOrEmpty(this.props.customCancelButtonStatement)
          ? this.props.customCancelButtonStatement || this.defaultCancelButtonText
          : new CmsText(this.props.privacyComponentText, 'privacy policy').get('cancel', this.defaultCancelButtonText);
    }

    isAcceptButtonDisabled(): boolean {
        const informationTransferringText = this.getTransferringStatement();
        return !(this.state.accepted && (IsStringNullOrEmpty(informationTransferringText) || !IsStringNullOrEmpty(informationTransferringText) && this.state.infromationTransferingAccepted));
    }

    handleEnterKey = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            this.setState({ accepted: !this.state.accepted });
        }
    };

    render() {
        if (this.props.hasError) {
            return <Navigate replace to={this.props.internalErrorRedirectUrl} />;
        }

        if (this.state.redirect) {
            if (this.props.internalRedirectUrl) {
                return <Navigate replace to={this.props.internalRedirectUrl} />;
            }
        }

        if (!this.props.hasCheckedForCustomPolicy) {
            return <div />;
        }

        let cmsText: CmsText = new CmsText(this.props.privacyComponentText, 'privacy policy');
        let headerText: string = !IsStringNullOrEmpty(this.props.customPrivacyHeader) ? this.props.customPrivacyHeader : cmsText.get('header', this.defaultHeaderText);
        let statementText: string = !IsStringNullOrEmpty(this.props.customPrivacyStatement) ? this.props.customPrivacyStatement : cmsText.get('statement', this.defaultPrivacyPolicyText);
        let explanation: string = cmsText.get('explanation', this.defaultExplanation)
            .replace(this.defaultAcceptButtonText, this.getAcceptButtonStatement())
            .replace(this.defaultCancelButtonText, this.getCancelButtonStatement());
        // Used a regular expression to find the link in the statementText
        const pdfLinkRegex = /<a[^>]*href=['"](.*?)['"][^>]*>(.*?)<\/a>/i;
        const match = statementText.match(pdfLinkRegex);
        // If a match is found, extract the link and the text inside the anchor tag
        const policyPdfLinkUrl = match ? match[1] : '';
        const policyPdfLinkText = match ? match[2] : '';
      
        return (
            <CmsDocumentTitle titlePath="privacy.documentTitle">
                <div className="content">
                    <div className="panel-info">
                        <h1 className="form-control-static signinpanel-greeting">
                            <span className="signinpanel-greeting-accent">
                                {headerText} </span>
                        </h1>
                        <div className="panel-border" />
                    </div>
                    <div className="signin-form">
                        <div className="form-field-input">
                            <label className="signinpanel-statement custom-control custom-checkbox" aria-label="checkbox privacy policy" htmlFor="privacy-policy-accepted-checkbox">
                                <span className="custom-control-description">
                                    <input id="privacy-policy-accepted-checkbox" type="checkbox" className="custom-control-input" checked={this.state.accepted} 
                                     onChange={(event) => this.setState({ accepted: event.target.checked })} aria-label="checkbox privacy policy"
                                     onKeyDown={this.handleEnterKey}
                                     aria-describedby="privacy-policy-accepted-instruction"
                                     aria-labelledby="privacy-policy-accepted-desc"
                                     aria-checked={this.state.accepted ? 'true' : 'false'}/>
                                </span>
                            </label>
                            <span id="privacy-policy-accepted-desc">
                                &nbsp;{renderHTML(statementText.replace(pdfLinkRegex, `<a href='${policyPdfLinkUrl}' target='_blank' rel='noopener noreferrer' aria-describedby='global-policy-pdf-desc'>${policyPdfLinkText}</a>`))}
                            </span>
                            <span id="privacy-policy-accepted-instruction" className="visually-hidden">
                                {`${cmsText.get('accessibilityPrivacyPolicyPrefix', 'Press the enter key or space bar to')} ${this.state.accepted ? 'un-check' : 'check'} ${cmsText.get('accessibilityPrivacyPolicySuffix', 'privacy policy checkbox.')}`}
                            </span>
                            <span id="global-policy-pdf-desc" className="visually-hidden">
                                {cmsText.get('accessibilityGlobalPolicyPdf', 'Press enter key to open the PDF document in a new window')}
                            </span>
                        </div>
                        {this.hasTransferringStatement() &&
                            <div className="form-field-input">
                                <label className="signinpanel-statement custom-control custom-checkbox" aria-label="checkbox privacy policy information transfer" htmlFor="privacy-policy-infromationTransferingAccepted-checkbox">
                                    <span className="custom-control-description">
                                        <input id="privacy-policy-infromationTransferingAccepted-checkbox" type="checkbox" className="custom-control-input" checked={this.state.infromationTransferingAccepted} onChange={(event) => this.setState({ infromationTransferingAccepted: event.target.checked })} aria-label="checkbox privacy policy information transfer" aria-describedby="privacy-policy-infromationTransfering-desc" />
                                    </span>
                                </label>
                                <span id="privacy-policy-infromationTransfering-desc">&nbsp;{renderHTML(this.getTransferringStatement() || "")}</span>
                            </div>
                        }
                        {this.hasCustomCheckbox() &&
                            <div className="form-field-input">
                                <label className="signinpanel-statement custom-control custom-checkbox" aria-label="checkbox privacy policy" htmlFor="privacy-policy-custom-checkbox">
                                    <span className="custom-control-description">
                                        <input id="privacy-policy-custom-checkbox" type="checkbox" className="custom-control-input" checked={this.state.customCheckboxAccepted} onChange={(event) => this.setState({ customCheckboxAccepted: event.target.checked })} aria-label="checkbox privacy policy" aria-describedby="privacy-policy-custom-checkbox-desc" />
                                    </span>
                                </label>
                                <span id="privacy-policy-custom-checkbox-desc">&nbsp;{renderHTML(this.props.customCheckboxStatement || "")}</span>
                            </div>
                        }
                        <div className="signinpanel-explanation">
                            <span>{explanation}</span>
                        </div>
                        <div className="form-button">
                            <button className="accept" type="button" disabled={this.isAcceptButtonDisabled()} onClick={this.onAccepted} aria-describedby='acceptButtonLabel'>{this.getAcceptButtonStatement()}</button>
                            <span id="acceptButtonLabel" className="visually-hidden">
                                {cmsText.get('accessibiltyAcceptButton','Press enter to accept the policy and continue to the next page')}
                            </span>
                            {/* redirect externally to origin because react-router won't reload the route if it is the same. */}
                            <button className="cancel" onClick={(event) => ExternalRedirect(window.location.origin)} aria-describedby='cancelButtonLabel'>{this.getCancelButtonStatement()}</button>
                            <span id="cancelButtonLabel" className="visually-hidden">
                                {cmsText.get('accessibiltyCancelButton','Press enter to cancel and exit the page.')}
                            </span>
                        </div>
                    </div>
                </div>
            </CmsDocumentTitle>);
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyPolicy);
