import { ThunkAction } from 'redux-thunk';
import State from './state';
import * as SigninBoxActions from '../actions/signin-box';
import * as Models from '../misc/models';

/**
 * The default initial state
 */
export const SigninBoxInitialState: State.SigninBox = {
    language: new Models.LanguageModel(),
    alltext: '',
    isLoaded: false,
    fetchingText: false
};

/**
 * Reducer for the whole sign in box
 */
const SigninBoxReducer =
    (state: State.SigninBox = SigninBoxInitialState, action: SigninBoxActions.Actions): State.SigninBox => {
        switch (action.type) {
            case SigninBoxActions.LanguageChangeSuccessType:
                return {
                    ...state,
                    language: action.payload.language,
                    alltext: action.payload.alltext,
                    isLoaded: true,
                    fetchingText: action.payload.fetchingText
                };
            case SigninBoxActions.FetchingSiteTextType:
                return {
                    ...state,
                    fetchingText: true
                };
            case SigninBoxActions.LanguageChangeErrorType:
                return {
                    ...state,
                    lastError: action.payload,
                    isLoaded: false,
                    fetchingText: false
                };
            default:
                return state;
        }
    };

export default SigninBoxReducer;