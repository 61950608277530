import { EmptyObject } from '../misc/common';
import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import PasswordReset, { PasswordResetDispatchProps, PasswordResetProps, PasswordResetFormFields } from '../components/password-reset/password-reset';
import * as PasswordResetActions from '../actions/password-reset';
import ContentWrapper from '../components/contentWrapper';
import CmsDocumentTitle from '../containers/cms-document-title';
import { Loading } from '../components/loading/loading';
import { Dispatch } from 'redux';

const mapStateToProps = (state: State.All) => ({
    tokenError: state.passwordreset.error,
    errorMessage: state.passwordreset.errorMessage,
    isSuccess: state.passwordreset.isSuccess,
    tokenIsValid: state.passwordreset.tokenIsValid,
    username: state.passwordreset.username,
    tokenValidated: state.passwordreset.tokenValidated,
    componentText: state.signinbox.alltext.passwordReset,
    passwordValidationText: state.signinbox.alltext.passwordValidation
});

// Note - subtle difference here in the Dispatch, where it needs to have an any type password to it, so it can handle ThunkActions
const mapDispatchToProps: MapDispatchToProps<PasswordResetDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    validateToken: (token: string) => {
        dispatch(PasswordResetActions.validateToken(token));
    },
    submitPasswordReset: (values: PasswordResetFormFields) => {
        return dispatch(PasswordResetActions.submitReset(values.password, values.verifyPassword));
    }
});

/**
 * Container for the sign in page
 * Used in the router, and uses the sign in box as a parent
 */
class PasswordResetContainer extends React.Component<PasswordResetProps, EmptyObject> implements React.ComponentLifecycle<PasswordResetProps, EmptyObject> {
    /**
     * Trigger the validation of the token here.
     * Result of validation will be shown in the component
     */
    componentDidMount() {
        this.props.validateToken(this.props.token);
    }

    render() {
        if (!this.props.tokenValidated) {
            return <Loading />;
        }
        return (
            <ContentWrapper>
                <CmsDocumentTitle titlePath="passwordReset.documentTitle">
                    <SigninBoxContainer>
                        <PasswordReset {...this.props} />
                    </SigninBoxContainer>
                </CmsDocumentTitle>
            </ContentWrapper>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordResetContainer);