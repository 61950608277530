import State from './state';
import * as SendActions from '../actions/login-link-expired';

/**
 * The default initial state
 */
export const LoginLinkExpiredReducerInitialState: State.LoginLinkExpired = {
    sendedSuccessful: false,
    alltext: ''
};

/**
 * Reducer for sending new invitation link
 */
const LoginLinkExpiredReducerReducer = (state: State.LoginLinkExpired = LoginLinkExpiredReducerInitialState, action: SendActions.Actions): State.LoginLinkExpired => {
    switch (action.type) {
        case SendActions.SendSuccessType:
            return { ...state, sendedSuccessful: true };
        default:
            return state;
    }
};

export default LoginLinkExpiredReducerReducer;