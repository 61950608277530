import * as React from 'react';
import PasswordExpiredContainer from '../containers/password-expired';
import ContentWrapper from '../components/contentWrapper';
import CmsDocumentTitle from '../containers/cms-document-title';

export default function PasswordExpiredPage(): JSX.Element {
    return (
        <ContentWrapper>
            <CmsDocumentTitle titlePath="passwordExpired.documentTitle">
                <PasswordExpiredContainer />
            </CmsDocumentTitle>
        </ContentWrapper>
    );
}