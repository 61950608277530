import * as React from 'react';
import PasswordResetContainer from '../containers/password-reset';
import { useLocation } from 'react-router-dom';

export default function PasswordResetPage() {
    /* 
     * Instead of using the props.match.params.token use the full path with the /password-reset/ starting path removed.
     * Due to the token containing /. In most cases the / is encoded and the variable works as expected, but some systems
     * decode the token before directing to the site. This causes only part of the token to be matched.
     *
     * e.g. /password-reset/llkjerw/slkjfoiwerlkj
     * The token parameter matches llkjerw instead of llkjerw/slkjfoiwerlkj
    */
    const location = useLocation();

    return <PasswordResetContainer token={decodeURIComponent(location.pathname.replace(/\/?password-reset\//i, ''))} />;
}