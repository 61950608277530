import { createAction, Action } from './action';
import { ThunkAction } from 'redux-thunk';
import * as PasswordResetService from '../services/password-reset-service';
import * as Models from '../misc/models';
import { ErrorReasonTextAction } from '../misc/common';
import State from '../reducers/state';
import { logError } from '../misc/error';
import { signinClearFailure } from '../actions/signin';
import { AnyAction } from 'redux';

export const ResetRequestResultType = 'RESET_REQUEST_RESULT';
export type ResetRequestResultAction = Action<typeof ResetRequestResultType, string>;
export function resetRequestResult(result: string): ResetRequestResultAction {
    return createAction(ResetRequestResultType, result);
}

// Password reset request submit username action
export function submitResetRequest(username: string): ThunkAction<Promise<void>, State.All, unknown, AnyAction> {
    return (dispatch) => {
        dispatch(signinClearFailure());

        return PasswordResetService.SubmitResetRequest(username)
            .then(() => {
                const errorText = dispatch(ErrorReasonTextAction(Models.ErrorReason.LoginPasswordResetEmailSent));
                dispatch(resetRequestResult(errorText));
            })
            .catch(r => logError('error submitting reset request', r));
    };
}

export type Actions = ResetRequestResultAction;