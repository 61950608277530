import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import { EmptyObject } from '../misc/common';
import State from '../reducers/state';
import Header from './header/header';
import BrandingWrapper from './branding/branding';
const ToastContainer = require('react-toastify').ToastContainer;
import FooterContainer from '../containers/footer';
import IntlProviderWrapper from './intl-provider-container';
import * as BrandingActions from '../actions/branding';
import { Loading } from './loading/loading';
import { changeLanguage } from '../actions/signin-box';
import { AppService } from '../services/app-service';
import { logErrorWithAlert } from '../misc/error';
import { CmsService } from '../services/cms-service';
import { Dispatch } from 'redux';

type ContentWrapperOwnProps = {
    brandingName?: string | undefined;
    isPreview?: boolean | undefined;
    languageId?: number | undefined;
    children?: React.ReactNode;
};

/**
 * State props for the Wrapper contentWrapper component
 */
export type ContentWrapperStateProps = {
    /**
     * Child components to render
     */
    children?: React.ReactNode;

    brandedLogoUrl: string;

    brandingLoaded: boolean;
    brandingLoading: boolean;

    cmsLoaded: boolean;
    cmsLoading: boolean;
    cmsError: AppService.ResponseError | undefined;
};

type ContentWrapperDispatchProps = {
    getBranding: (brandingName: string | undefined, isPreview: boolean) => void;
    loadLanguage: (languageId: number | undefined) => void;
};

const mapStateToProps = (state: State.All) => ({
    brandedLogoUrl: state.branding.logoUrl,
    brandingLoaded: state.branding.isLoaded,
    brandingLoading: state.branding.isLoading,
    cmsLoaded: state.signinbox.isLoaded,
    cmsLoading: state.signinbox.fetchingText,
    cmsError: state.signinbox.lastError
});

const mapDispatchToProps: MapDispatchToProps<ContentWrapperDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    getBranding: (brandingName: string | undefined, isPreview: boolean) => {
        dispatch(BrandingActions.getBranding(brandingName, isPreview));
    },
    loadLanguage: (languageId: number | undefined) => {
        dispatch(changeLanguage(languageId));
    }
});

type ContentWrapperAllProps = ContentWrapperOwnProps & ContentWrapperStateProps & ContentWrapperDispatchProps;

/**
 * Wrapper around most pages that includes header and footer
 * Ensures that branding and cms content are loaded before displaying
 * any content reliant upon them or the child components
 */
class ContentWrapper extends React.Component<ContentWrapperAllProps, EmptyObject> {
    componentDidMount() {
        this.updateContent();
    }

    componentDidUpdate() {
        this.updateContent();
    }

    render() {
        if (!this.props.brandingLoaded) {
            return <Loading />;
        }

        if (!this.props.cmsLoaded) {
            if (this.props.cmsError) {
                return <div>Unable to load language</div>;
            }

            return <Loading />;
        }

        return (
            <div className={CmsService.GetSiteFontStyle()}>
                <IntlProviderWrapper>
                    <div className="App">
                        <Header logoUrl={this.getLogoUrl()}
                                brandName={this.getBrandName()} />
                        <main className="background-large">
                            <div className="hero-image" />
                            <div className="main-center">
                                <div className="panel-body signinpanel">
                                    <BrandingWrapper />
                                        {this.props.children}
                                    <ToastContainer />
                                </div>
                            </div>
                        </main>
                        <FooterContainer />
                    </div>
                </IntlProviderWrapper>
            </div>
        );
    }

    private getLogoUrl() {
        return (this.props.brandedLogoUrl === null || !this.props.brandedLogoUrl || this.props.brandedLogoUrl.length === 0 ? '/images/logo-kornferry.svg' : this.props.brandedLogoUrl);
    }

    private getBrandName() {
        return this.props.brandingName || 'Korn Ferry';
    }

    private updateContent = () => {
        if (!this.props.brandingLoaded) {
            if (!this.props.brandingLoading) {
                this.props.getBranding(this.props.brandingName, this.props.isPreview || false);
            }
        } else {
            // Do not attempt to load cms until branding is loaded - it relies upon branding to determine
            // whether to load a themed manifest 
            if (!this.props.cmsLoaded) {
                if (this.props.cmsError) {
                    logErrorWithAlert('Unable to load language', this.props.cmsError.message ? this.props.cmsError.message : 'Unable to load language');
                } else if (!this.props.cmsLoading) {
                    this.props.loadLanguage(this.props.languageId);
                }
            }
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentWrapper);