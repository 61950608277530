import { CmsText } from './cms-text';

/**
 * Class to handle Manifest metadata.
 */
export class CmsManifestMetaData {
  readonly id: string;
  readonly version: string;

  constructor(cmsText: CmsText) {
    const docMetaData = new CmsText(cmsText, 'docMetaData');
    this.id = docMetaData.get('id', '');
    this.version = docMetaData.get('tag', '');
  }
}