import * as React from 'react';
import ProjectExpiredContainer from '../containers/project-expired';
import ContentWrapper from '../components/contentWrapper';
import CmsDocumentTitle from '../containers/cms-document-title';
import { ExpiredProjectLoginMethod } from '../misc/models';

export default function ProjectExpired(method: ExpiredProjectLoginMethod): JSX.Element {
    /**
     * When we show 'project-expired' page for project registration link or for invitation link - this is independent page.
     * For username and password - this is only component for signin-page and we don't need wrapper.
     */
    switch (method) {
        case ExpiredProjectLoginMethod.ProjectRegistrationLink:
        case ExpiredProjectLoginMethod.InvitationLink:
            return (
                <ContentWrapper>
                    <CmsDocumentTitle titlePath="projectExpired.documentTitle">
                        <ProjectExpiredContainer method={method}/>
                    </CmsDocumentTitle>
                </ContentWrapper>
            );

        case ExpiredProjectLoginMethod.UsernameAndPassword:
            return (
                <CmsDocumentTitle titlePath="projectExpired.documentTitle">
                    <ProjectExpiredContainer method={method}/>
                </CmsDocumentTitle>
            );

        default:
            throw new Error(`Unsupported method: ${method}`);
    }
}