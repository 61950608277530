import * as React from 'react';
import CmsDocumentTitle from '../containers/cms-document-title';
import ErrorContainer from '../containers/error-container';
import ContentWrapper from '../components/contentWrapper';
import { useParams } from 'react-router-dom';

export default function ErrorPage(): JSX.Element {
    const {brandingName, initialError} = useParams();
    return (
        <ContentWrapper brandingName={brandingName}>
            <CmsDocumentTitle titlePath="signin.documentTitle">
                <ErrorContainer brandingName={brandingName} initialError={initialError} />
            </CmsDocumentTitle>
        </ContentWrapper>
    );
}