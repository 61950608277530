import { EmptyObject } from '../misc/common';
import { LoginLinkExpiredDispatchProps, LoginLinkExpiredStateProps, LoginLinkExpiredProps, LoginLinkExpiredRouteProps } from '../components/login-link-expired/login-link-expired';
import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import * as SendActions from '../actions/login-link-expired';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import LoginLinkExpired from '../components/login-link-expired/login-link-expired';
import { Dispatch } from 'redux';

const mapStateToProps = (state: State.All) => ({
    componentText: state.signinbox.alltext.loginLinkExpired,
    sendedSuccessful: state.loginLinkExpired.sendedSuccessful
});

const mapDispatchToProps: MapDispatchToProps<LoginLinkExpiredDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    sendNewInvitationLink: (token: string) => {
        return dispatch(SendActions.SendNewInvitationLink(token));
    }
});

/**
 * Container for the sending link page
 * Uses the sign in box as a parent
 */
class LoginLinkExpiredContainer extends React.Component<LoginLinkExpiredProps & LoginLinkExpiredStateProps, EmptyObject> implements React.ComponentLifecycle<LoginLinkExpiredProps, EmptyObject> {
    render() {
        return (
            <div>
                <SigninBoxContainer>
                    <LoginLinkExpired {...this.props} />
                </SigninBoxContainer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginLinkExpiredContainer);