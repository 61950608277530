import { ExceptionInfo } from '_debugger';
import * as logger from './logger';

const toast = require('react-toastify').toast;

/**
 * Very basic error handler with message for the user, should be replace with Redux actions and something like
 * toast (https://fkhadra.github.io/react-toastify/) for displaying messages to the user rather than basic alert.
 */

/**
 * General error logger and message display to the user.
 * @param userMessage Message to display to the user
 * @param logMessage Message to store in the error logs
 * @param exception exception details.
 */
export const logErrorWithAlert = (userMessage: string, logMessage: string, exception?: ExceptionInfo) => {
    logError(logMessage, exception);
    toast.error(userMessage, toastErrorOptions);
};

/**
 * Options object for toaster error alert see.https://www.npmjs.com/package/react-toastify#demo
 */
const toastErrorOptions = {
    autoClose: 200000000,
    type: toast.TYPE.INFO,
    hideProgressBar: false,    
    position: toast.POSITION.TOP_RIGHT
};

/**
 * General error logger and display alert on browser window (using toastify plugin)
 * @param logMessage Message to store in the error logs
 * @param exception exception details.
 */
export const logError = (logMessage: string, exception?: ExceptionInfo) => {
    logger.logError({ logMessage, exception });
    toast.error('There was an error please contact your administrator, more details are available in the error log', toastErrorOptions);
};

/**
 * General error logger without display of error to browser window.
 * @param logMessage Message to store in the error logs
 * @param exception exception details.
 */
export const logErrorWithOutAlert = (logMessage: string, exception?: ExceptionInfo) => {
    logger.logError({ logMessage, exception });
};

/**
 * Method to log errors with redux.see redux-catch for more info.
 * @param error The error message
 * @param getState Method to get current state 
 * @param lastAction Action that triggered error
 * @param dispatch Handle to dispatch, can be used to dispatch an action due to the error using the dispatch parameter 
 */
export const logReduxError = (error: any, getState: any, lastAction: any, dispatch: any) => {
    logger.logFatal('fatal error redux', { error, lastAction });
    toast.error('System Error');
};