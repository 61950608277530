import * as React from 'react';
import { connect } from 'react-redux';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import PasswordExpired from '../components/password-expired/password-expired';

const mapStateToProps = (state: State.All) => ({
    componentText: state.signinbox.alltext.passwordExpired,
});

export type PasswordExpiredStateProps = {
    componentText: any,
};

const PasswordExpiredContainer: React.FC<PasswordExpiredStateProps> = (props) => (
    <SigninBoxContainer>
        <PasswordExpired {...props} />
    </SigninBoxContainer>
);

export default connect(mapStateToProps)(PasswordExpiredContainer);