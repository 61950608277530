import { createAction, Action } from './action';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as PasswordResetService from '../services/password-reset-service';
import * as Models from '../misc/models';
import State from '../reducers/state';
import { ErrorReasonTextAction, ExternalRedirect } from '../misc/common';
import { logError } from '../misc/error';
import { finishGetBranding } from '../actions/branding';
import { signinClearFailure } from '../actions/signin';
import { AnyAction } from 'redux';
import { ErrorReason } from '../misc/models';

export const ResetResultType = 'RESET_RESULT';
export type ResetResultAction = Action<typeof ResetResultType, Models.ResetResult>;
export function resetResult(result: Models.ResetResult): ResetResultAction {
    return createAction(ResetResultType, result);
}

export const ValidateTokenResultType = 'VALIDATE_TOKEN_RESULT';
export type ValidateTokenResultAction = Action<typeof ValidateTokenResultType, Models.ValidateResetTokenResult>;
export function validateTokenResult(result: Models.ValidateResetTokenResult): ValidateTokenResultAction {
    return createAction(ValidateTokenResultType, result);
}

/**
 * Validate a password reset token
 * @param password The token to validate
 */
export function validateToken(token: string): ThunkAction<void, null, unknown, AnyAction> {
    return (dispatch, getState) => {
        if (token !== undefined && token.length > 0) {
            PasswordResetService.ValidateResetToken(token)
                .then(r => {
                    if (r.brandingStyles !== undefined && r.brandingStyles.styles !== '') {
                        dispatch(finishGetBranding(r.brandingStyles));
                    }
                    dispatch(validateTokenResult(r));
                })
                .catch(r => {
                    logError('error validating token', r);
                    invalidTokenResponse(dispatch);
                });
        } else {
            // Don't bother with API call, set as invalid
            invalidTokenResponse(dispatch);
        }
    };
}

function invalidTokenResponse(dispatch: Dispatch<any>) {
    let result = new Models.ValidateResetTokenResult;
    result.tokenIsValid = false;
    dispatch(validateTokenResult(result));
}

/**
 * Submit a password reset
 * @param password The new password
 * @param verifyPassword The confirmation of the new password
 */
export function submitReset(password: string, verifyPassword: string): ThunkAction<Promise<void>, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        dispatch(signinClearFailure());
        return new Promise<void>((resolve, reject) => {
            let token = getState().passwordreset.token;
            PasswordResetService.SubmitPasswordReset(token, password, verifyPassword)
                .then(r => {
                    r.errorText = dispatch(ErrorReasonTextAction(r.error));
                    dispatch(resetResult(r));
                    if (r.error === ErrorReason.LoginPasswordChanged && r.redirectUrl) {
                        ExternalRedirect(r.redirectUrl);
                    }
                    resolve();
                })
                .catch(r => { logError('error submitting response', r); resolve(); });
        });
    };
}

export type Actions = ValidateTokenResultAction | ResetResultAction;