import State from '../reducers/state';
import { Action, createAction } from './action';
import { SubmitPrivacyPolicyResponse, GetCustomPolicy } from '../services/privacy-policy-service';
import { ThunkAction } from 'redux-thunk';
import { ExternalRedirect } from '../misc/common';
import { CmsManifestMetaData } from '../misc/cms-manifest-metadata';
import { AnyAction } from 'redux';

export const GetCustomPrivacyPolicySuccessType = 'PRIVACY_POLICY_SUCCESS';
export type GetCustomPrivacyPolicyChangeAction = Action<typeof GetCustomPrivacyPolicySuccessType, State.PrivacyPolicy>;
export function getCustomPrivacyPolicySuccess(
    customPrivacyHeader: string,
    customPrivacyStatement: string,
    customCheckboxStatement: string | null,
    customTransferringDataStatement: string | null,
    customAcceptButtonStatement: string | null,
    customCancelButtonStatement: string | null): GetCustomPrivacyPolicyChangeAction {
    return createAction(
        GetCustomPrivacyPolicySuccessType,
        {
            hasCheckedForCustomPolicy: true,
            customPrivacyHeader,
            customPrivacyStatement,
            customCheckboxAccepted: null,
            customCheckboxStatement: customCheckboxStatement,
            customTransferringDataStatement: customTransferringDataStatement,
            customAcceptButtonStatement: customAcceptButtonStatement,
            customCancelButtonStatement: customCancelButtonStatement,
            hasError: false
        });
}

export const PrivacyPolicyResponseType = 'PRIVACY_POLICY_RESPONSE';
export type PrivacyPolicyResponseAction = Action<typeof PrivacyPolicyResponseType, State.PrivacyPolicyResponse>;
export function PrivacyPolicyResponse(customCheckboxAccepted: boolean | null, hasError: boolean): PrivacyPolicyResponseAction {
    return createAction(PrivacyPolicyResponseType, { customCheckboxAccepted: customCheckboxAccepted, hasError: hasError });
}

export function getCustomPrivacyPolicy(clientId: number, languageId: number): ThunkAction<void, null, unknown, AnyAction> {
    return (dispatch) => {
        GetCustomPolicy(clientId, languageId)
            .then(r => {
                dispatch(getCustomPrivacyPolicySuccess(
                    r.customPrivacyHeader,
                    r.customPrivacyStatement,
                    r.customCheckboxStatement,
                    r.customTransferringDataStatement,
                    r.customAcceptButtonStatement,
                    r.customCancelButtonStatement));
            });
    };
}

/**
 * Submit the privacy policy response to state
 */
export function submitPrivacyPolicyResponseState(customCheckboxAccepted: boolean | null, hasError: boolean): ThunkAction<void, null, unknown, AnyAction> {
    return (dispatch) => {
        dispatch(PrivacyPolicyResponse(customCheckboxAccepted, hasError));
    };
}

/**
 * Submit the privacy policy response to the server
 */
export function submitPrivacyPolicyResponseServer(token: string | null, policyAccepted: boolean, customCheckboxAccepted: boolean | null, manifest: CmsManifestMetaData | null): ThunkAction<void, null, unknown, AnyAction> {
    return (dispatch) => {
        if (token === null) {
            throw 'Cannot submit privacy policy response to the server without a token';
        } else {
            SubmitPrivacyPolicyResponse(token, policyAccepted, customCheckboxAccepted, manifest)
                .then(x => {
                    ExternalRedirect(x);
                })
                .catch(x => { dispatch(PrivacyPolicyResponse(customCheckboxAccepted, true)); });
        }
    };
}

export type Actions = GetCustomPrivacyPolicyChangeAction | PrivacyPolicyResponseAction;