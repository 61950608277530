import 'isomorphic-fetch';
import { AppService } from './app-service';
import * as Models from '../misc/models';

/* Service to get branding for a client */
export namespace BrandingService {

    const brandingRestEndPoint = 'branding/styles';
    const brandingFullEndPoint = AppService.buildActionUrlFromParts(process.env.REACT_APP_CDN_API_URL, process.env.REACT_APP_API_VERSION, brandingRestEndPoint);

    export function GetBranding(branding: string | undefined, isPreview: boolean = false): Promise<Models.BrandingResult> {
        if (branding) {
            let params: AppService.GetParam[] = [];
            params.push(new AppService.GetParam('name', branding));
            params.push(new AppService.GetParam('isPreview', String(isPreview)));

            if (isPreview) {
                return AppService.GetThenToObject('branding/styles', params, Models.BrandingResult);
            }

            // We need to get the version from the server so that if the branding gets updated, CloudFront
            // will get the new version and cache it

            // create a new promise as we want the result of the branding request, not the version result
            return new Promise<Models.BrandingResult>((resolve, reject) => {
                AppService.GetThenToObject('branding/version', params, Models.BrandingResult)
                    .then(j => {
                        params.push(new AppService.GetParam('version', j.version));
                        AppService.GetThenToObject(brandingFullEndPoint, params, Models.BrandingResult)
                            .then(m => resolve(m))
                            .catch(error => reject(error));
                    })
                    .catch(error => reject(error));
            });
        }

        return Promise.resolve(new Models.BrandingResult());
    }
}