import { WrappedFieldMetaProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { CmsText } from '../misc/cms-text';

const validators = require('redux-form-validators');

type RenderInputFieldType = {
    input: any,
    label: string,
    placeholder?: string,
    type: string,
    id: string,
    containerClass: string,
    autoComplete?: string,
    meta: WrappedFieldMetaProps,
    componentText: any,
    disabled?: boolean
    onValidationChange?: (meta: WrappedFieldMetaProps) => void;
};

/**
 * Renders an input and label.
 * Use as <Field component={renderInputField}>
 * Any additional property can be put on the <Field>, added to RenderInputFieldType and used in this method
 * @param args The args, as defined as seperate properties on the <Field> and added to RenderInputFieldType
 */
export function renderInputField(args: RenderInputFieldType): JSX.Element {
  let hasError: boolean = args.meta.touched && args.meta.error;
  let feedbackId: string = hasError ? args.id + "-feedback" : "";
  let containerClass =
    (args.containerClass && args.containerClass.length > 0
      ? args.containerClass
      : "") + (hasError ? "invalid" : "");

  // Call the onValidationChange function if provided (e.g., for email)
  if (args.onValidationChange) {
    args.onValidationChange(args.meta); // Pass the meta info for validation
  }

  return (
    <div className={containerClass + " form-field-input"}>
      <label htmlFor={args.id}>{args.label}</label>
      {hasError && (
        <span className="form-control-feedback" id={feedbackId}>
          {args.meta.error}
        </span>
      )}
      <input
        {...args.input}
        autoComplete={args.autoComplete}
        placeholder={args.placeholder ? args.placeholder : args.label}
        type={args.type}
        id={args.id}
        disabled={args.disabled}
      />
    </div>
  );
}

// ------------Custom password validator-----------

export const PasswordValidationComponent = ({
  input,
  meta,
  label,
  id,
  onValidationChange,
  componentText,
  showPasswordReuseNote = true
}: any): JSX.Element => {
  const [validation, setValidation] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const [isTouched, setIsTouched] = useState(false); // Track whether the user has started typing

  const cmsText = new CmsText(componentText, 'passwordValidation');
  
  const validatePassword = (password: string) => {
    if (password === "") {
      setIsTouched(false); // Hide messages if password is empty
    } else {
      setIsTouched(true); // Show messages if user has started typing
    }

    const updatedValidation = {
      minLength: password.length >= 12,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };

    setValidation(updatedValidation);

    // Check if all conditions are satisfied
    const allConditionsSatisfied =
      updatedValidation.minLength &&
      updatedValidation.uppercase &&
      updatedValidation.lowercase &&
      updatedValidation.number &&
      updatedValidation.specialChar;

    // Call the callback to inform the parent component
    onValidationChange(allConditionsSatisfied);
  };

  let hasError: boolean = meta.touched && meta.error;

  let containerClass =
    (isTouched || hasError) &&
    !(
      validation.minLength &&
      validation.uppercase &&
      validation.lowercase &&
      validation.number &&
      validation.specialChar
    )
      ? "invalid"
      : "";

  return (
    <div className={containerClass + " form-field-input"}>
      <label htmlFor={id}>{label}</label>
      {hasError && (
        <span className="form-control-feedback" id={id} role="alert" aria-live="assertive">
          {meta.error}
        </span>
      )}
      <input
        {...input}
        type="password"
        id={id}
        placeholder={label}
        onChange={(e) => {
          input.onChange(e); // Keep redux-form in sync
          validatePassword(e.target.value);
        }}
      />
      {isTouched && (
     <div className="custom-validation-message-container" aria-live="polite">
      <p>{cmsText.get("validationIntro", "In order to protect your account, make sure your password:")}</p>
      <ul className="validation-messages-list" role="alert">
        <li className={validation.minLength ? "valid" : "invalid"}>
          {cmsText.get("minLength", "Password must be at least 12 characters long.")}
        </li>
        <li className={validation.uppercase ? "valid" : "invalid"}>
          {cmsText.get("uppercase", "Password must contain at least one uppercase letter.")}
        </li>
        <li className={validation.lowercase ? "valid" : "invalid"}>
          {cmsText.get("lowercase", "Password must contain at least one lowercase letter.")}
        </li>
        <li className={validation.number ? "valid" : "invalid"}>
          {cmsText.get("number", "Password must contain at least one number.")}
        </li>
        <li className={validation.specialChar ? "valid" : "invalid"}>
          {cmsText.get("specialChar", "Password must contain at least one special character. (e.g. # or %)")}
        </li>
      </ul>
      {showPasswordReuseNote && (
            <p className="note">
          <strong>{cmsText.get("noteLabel", "Note:")}</strong> {cmsText.get("passwordNote", "Password must not have been used as one of your previous 12 passwords.")}
            </p>
          )}
   </div>
      )}
    </div>
  );
};
  


const passwordRegexes: string[] = [
    '[a-z]+', // lowercase
    '[A-Z]+', // uppercase
    '[0-9]+', // digit
    '[\!\£\$\^\*\-_\`\[\]\@\#\~\:\|\/\\\,\.\¬]+' // special
];

const PASSWORD_ERROR = <FormattedMessage id="form.errors.alpha" defaultMessage="The password must be at least 8 characters long, and contain at least 3 of the following: upper case letters, lower case letters, numbers and special characters (e.g. # or %). It must not have been used as one of your previous 12 passwords, and must not be your email address or username, nor a common password permutation, e.g. P4ssw0rd." />;

export const PasswordValidator = validators.addValidator({
    defaultMessage: PASSWORD_ERROR,
    validator: function (options: any, value: string, allValues: any) {
        let matches = 0;
        passwordRegexes.forEach(reg => {
            let regex = new RegExp(reg);
            if (regex.test(value)) {
                matches++;
            }
        });

        return value.length >= 8 && matches >= 3;
    }
});
