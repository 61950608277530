import * as React from 'react';

/**
 * The props for the Header component
 */
type HeaderProps = {
    logoUrl: string;
    brandName: string;
};

/**
 * The header component
 */
export default function Header(props: HeaderProps): JSX.Element {
    return (
        <header>
            <nav>
                <div className="navigation-brand">
                    <img src={props.logoUrl} alt={props.brandName} />
                </div>
            </nav>
        </header>);
}
