import SigninReducer from './signin';
import SigninBoxReducer from './signin-box';
import PasswordResetReducer from './password-reset';
import PasswordResetRequestReducer from './password-reset-request';
import OpenInvitationReducer from './open-invitation';
import PrivacyPolicyReducer from './privacy-policy';
import BrandingReducer from './branding';
import LoginLinkExpiredReducer from './login-link-expired';
import { reducer as formReducer } from 'redux-form';
import { combineReducers, ReducersMapObject } from 'redux';
import State from './state';

export const AppReducer = combineReducers<State.All>({
    signinbox: SigninBoxReducer,
    signin: SigninReducer,
    passwordresetrequest: PasswordResetRequestReducer,
    passwordreset: PasswordResetReducer,
    openinvitation: OpenInvitationReducer,
    form: formReducer,
    branding: BrandingReducer,
    privacypolicy: PrivacyPolicyReducer,
    loginLinkExpired: LoginLinkExpiredReducer
});