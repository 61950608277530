import * as React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import PasswordResetRequest, { PasswordResetRequestStateProps, PasswordResetRequestDispatchProps, PasswordResetRequestFormFields, PasswordResetRequestProps } from '../components/password-reset/password-reset-request';
import SigninBoxContainer from './signin-box';
import { EmptyObject } from '../misc/common';
import * as PasswordResetRequestActions from '../actions/password-reset-request';
import State from '../reducers/state';
import { Dispatch } from 'redux';

const mapStateToProps = (state: State.All) => ({
    result: state.passwordresetrequest.result,
    componentText: state.signinbox.alltext.passwordResetRequest
});

// Note - subtle difference here in the Dispatch, where it needs to have an any type password to it, so it can handle ThunkActions
const mapDispatchToProps: MapDispatchToProps<PasswordResetRequestDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    submitPasswordResetRequest: (values: PasswordResetRequestFormFields) => {
        return dispatch(PasswordResetRequestActions.submitResetRequest(values.username));
    }
});

/**
 * Container for the password reset request page.
 */
class PasswordResetRequestContainer extends React.Component<PasswordResetRequestProps, EmptyObject> {
    render() {
        return (
            <SigninBoxContainer>
                <PasswordResetRequest {...this.props} />
            </SigninBoxContainer>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordResetRequestContainer);