import { EmptyObject, ExternalRedirect } from '../misc/common';
import Signin, { SigninDispatchProps, SigninFormFields, SigninProps } from '../components/signin/signin';
import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import PrivacyPolicy from '../components/privacy-policy/privacy-policy';
import * as SigninActions from '../actions/signin';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import * as Models from '../misc/models';
import { CmsService } from '../services/cms-service';
import ProjectExpired from '../pages/project-expired';
import { Dispatch } from 'redux';

const mapStateToProps = (state: State.All) => ({
    errorReason: state.signin.errorReason,
    errorMessage: state.signin.errorMessage,
    componentText: state.signinbox.alltext.signin,
    privacyPolicyAccepted: state.signin.acceptedPrivacyPolicy,
    privacyPolicyClientId: state.signin.privacyPolicyClientId,
    signInSuccessful: state.signin.signInSuccess,
    redirectUrl: state.signin.redirectUrl,
    privacyPolicyToken: state.signin.privacyPolicyToken,
    userName: state.signin.userName || ''
});

// Note - subtle difference here in the Dispatch, where it needs to have an any type password to it, so it can handle ThunkActions
const mapDispatchToProps: MapDispatchToProps<SigninDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    submitSignin: (values: SigninFormFields) => {
        return dispatch(SigninActions.submitSignin(values.username, values.password, CmsService.GetLoginLanguageId()));
    },
    raiseFailure: (reason: Models.ErrorReason) => {
        dispatch(SigninActions.signinFailureFromReason(reason));
    }
});

type SigninContainerProps = {
    redirectUrl: string,
    privacyPolicyToken: string | null
};

/**
 * Container for the sign in page
 * Used in the router, and uses the sign in box as a parent
 */
class SigninContainer extends React.Component<SigninProps & SigninContainerProps, EmptyObject> implements React.ComponentLifecycle<SigninProps, EmptyObject> {

    /**
     * Check if we have an initial error to display, passed from the route.
     * If we do, we need to display it the same as a sign in failure.
     * We only want to do this on initial component load.
     */
    componentDidMount() {
        if (this.props.initialError !== undefined) {
            let val = parseInt(this.props.initialError);

            // Check the property is actually a number and in our reason enum. Prevents url tampering.
            if (!isNaN(val) && val in Models.ErrorReason) {
                this.props.raiseFailure(val);
            }
        }
    }

    componentDidUpdate() {
        if (this.props.privacyPolicyAccepted && this.props.signInSuccessful) {
            ExternalRedirect(this.props.redirectUrl);
        }
    }

    render() {
        if (this.props.errorReason === Models.ErrorReason.ProjectExpired) {
            return ProjectExpired(Models.ExpiredProjectLoginMethod.UsernameAndPassword);
        }

        return (
            <div>
                { (!this.props.privacyPolicyAccepted && this.props.signInSuccessful) && <PrivacyPolicy internalRedirectUrl={this.props.redirectUrl} internalErrorRedirectUrl={'/error/34'} clientId={this.props.privacyPolicyClientId} token={this.props.privacyPolicyToken}/> }
                { !this.props.signInSuccessful && <SigninBoxContainer><Signin {...this.props} /></SigninBoxContainer> }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SigninContainer);