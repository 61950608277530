import * as React from 'react';
import OpenInvitationContainer from '../containers/open-invitation';
import { useLocation, useParams } from 'react-router-dom';
import * as qs from 'query-string';
import { KeyValuePair } from '../misc/models';

export default function OpenInvitationPage() {
    const location = useLocation();
    const { ppaccepted, id } = useParams();
    
    const getQueryString = (location: any): KeyValuePair[] => {
        let parsed: Object = qs.parse(location.search);
        return Object.keys(parsed).map(key => new KeyValuePair(key, parsed[key]));
    };

    let privacyPolicyAccepted: boolean = ppaccepted !== undefined && ppaccepted === 'true';
    let externalMetadata = getQueryString(location);

    return (
        <OpenInvitationContainer 
            id={decodeURIComponent(id || '')} 
            ppaccepted={privacyPolicyAccepted} 
            externalmetadata={externalMetadata} 
            getSettings={() => {}} 
            submitOpenInvitation={(): any => {}}
            submitSsoEmailGate={(): any => {}} />
    );
}
