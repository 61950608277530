import * as React from 'react';
import CmsDocumentTitle from '../containers/cms-document-title';
import SigninContainer from '../containers/signin';
import { useLocation, useParams } from 'react-router-dom';
import ContentWrapper from '../components/contentWrapper';
import { parse } from 'query-string';

export type SigninPageProps = {
    userName?: string; // Adding username as a prop
};

export default function SigninPage({ userName }: SigninPageProps): JSX.Element {
    
    let location = useLocation();
    let {isPreview, brandingName, initialError} = useParams();
    let languageIdStr: string = parse(location.search).languageId;
    let languageId: number | undefined = languageIdStr ? parseInt(languageIdStr, 10) : undefined;
    if (!languageId) {
        languageId = undefined;
    }
    return (
        <ContentWrapper brandingName={brandingName} isPreview={isPreview === 'true'} languageId={languageId}>
            <CmsDocumentTitle titlePath="signin.documentTitle">
            <SigninContainer initialError={initialError} username={userName} />
            </CmsDocumentTitle>
        </ContentWrapper>
    );
}