import { Action, createAction } from './action';
import { ThunkAction } from 'redux-thunk';
import State from '../reducers/state';
import * as Models from '../misc/models';
import { BrandingService } from '../services/branding-service';
import { storeBranding, clearBrandingSession } from '../misc/session';
import { AnyAction } from 'redux';

export const GetBrandingStartType = 'GET_BRANDING_START';
export const GetBrandingFinishType = 'GET_BRANDING_FINISH';
export const SetPreviewModeType = 'SET_PREVIEW_MODE';
export type GetBrandingStartAction = Action<typeof GetBrandingStartType, boolean>;
export type GetBrandingFinishAction = Action<typeof GetBrandingFinishType, Models.BrandingResult>;
export type SetPreviewModeAction = Action<typeof SetPreviewModeType, boolean>;

export function startGetBranding(): GetBrandingStartAction {
    // Clear any previously stored branding. 
    clearBrandingSession();
    return createAction(GetBrandingStartType, true);
}

/**
 * 
 * 
 * @export
 * @param {Models.BrandingResult} branding 
 * @param {boolean} [isPreview=false] 
 * @returns {GetBrandingFinishAction} 
 */
export function finishGetBranding(branding: Models.BrandingResult, isPreview: boolean = false): GetBrandingFinishAction {
    if (branding.styles === null) {
        branding.styles = ' ';
    }

    if (!isPreview) {
        storeBranding(branding);
    }

    setPreviewMode(isPreview);

    return createAction(GetBrandingFinishType, branding);
}

export function getBranding(brandingName: string | undefined, isPreview: boolean = false): ThunkAction<Promise<any>, State.All, unknown, AnyAction> {
    return (dispatch) => {
        dispatch(startGetBranding());
        return BrandingService.GetBranding(brandingName, isPreview)
            .then(branding => {
                dispatch(finishGetBranding(branding, isPreview));
            });
    };
}

export function setPreviewMode(isPreview: boolean) {
    return createAction(SetPreviewModeType, isPreview);
}

export type Actions = GetBrandingStartAction | GetBrandingFinishAction | SetPreviewModeAction;