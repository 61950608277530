import 'isomorphic-fetch';
import { AppService } from './app-service';

/* Service to interact with CMS */
export namespace CmsService {
    const cmsManifest = process.env.REACT_APP_CMS_Manifest;
    const cmsCachedUrl = process.env.REACT_APP_CDN_CACHED_URL;
    const cmsRestEndPoint = 'sitetext';
    const cmdFullEndPoint = AppService.buildActionUrlFromParts(process.env.REACT_APP_CDN_API_URL, process.env.REACT_APP_API_VERSION, cmsRestEndPoint);
    const isLanguageChangedByUser = 'isLanguageChangedByUser';
    const VietnameseLanguageId = 41;

    /** Method to get all the text(from CMS) for login site in the passed in language
     *  TODO This method can be used once talentq api to return text (from cms ) and languages is in place. 
     * @export
     * @param {number} languageId   
     * @returns {Promise<any>} 
     */
    export function GetLoginSiteText(languageId: number, preview: boolean, brandingName: string | undefined): Promise<any> {
        // On test / staging / production, CloudFront the text is requested via CloudFront which will then forward the request
        // to the server and caches the results. Subsequent requests with the same URL will be served from the cache
        if (cmsCachedUrl !== undefined && cmsCachedUrl.length > 0) {
            return AppService.getObject(cmsCachedUrl + '/' + languageId + '.json', (json) => <any> json, { branding: brandingName, preview: preview })
                .then(j => {
                    let siteText = j.cmsText;
                    siteText.allLanguages = j.allLanguages;
                    return siteText;
                });
        }

        return AppService.getObject(cmdFullEndPoint, (json) => <any> json, { languageId: languageId, manifest: cmsManifest, type: 'json', branding: brandingName, preview: preview })
            .then(j => {
                let siteText = j.cmsText;
                siteText.allLanguages = j.allLanguages;
                return siteText;
            });
    }

    /** Method to get the site language set in session storage
     * @returns {number} The site language Id
     */
    export function GetSiteLanguage(): number {
        let language = sessionStorage.getItem('language');
        return language == null ? GetDefaultLanguage() : parseInt(language, 10);
    }

    export function GetDefaultLanguage(): number {
        let defaultLanguage: string = process.env.REACT_APP_DEFAULT_LANGUAGES;

        return parseInt(defaultLanguage, 10);
    }

    /** Method returns language's identificator for sending in authorization method
     * If user changed system's language (for example, by drop-down list), nonzero value will be returned
     * and user's prefered language's settings in database will be updated.
     * If zero value returns, system will use language's settings from database.
     */
    export function GetLoginLanguageId(): number {
        return sessionStorage.getItem(isLanguageChangedByUser) === 'true' ? GetSiteLanguage() : 0;
    }

    /** Store the selected language in session storage    
     * @param {number} languageId LanguageId to save in session storage
     */
    export function SetSiteLanguage(languageId: number): void {
        sessionStorage.setItem('language', String(languageId));
    }

    /**
     * Save language changes in sessionStorage
     * @param set: true - save changes, false - remove
     */
    export function SetLanguageChangesAttributeInSessionStorage(set: boolean) {
        if (set) {
            sessionStorage.setItem(isLanguageChangedByUser, 'true');
        } else {
            sessionStorage.removeItem(isLanguageChangedByUser);
        }
    }

    /**
     * Returns style with font of the site
     * @returns style with font of the site
     */
    export function GetSiteFontStyle(): string {
        return GetSiteLanguage() !== VietnameseLanguageId ? 'body' : 'bodyArial';
    }
}