import React, { Fragment, useEffect } from 'react';
import { CmsText } from '../misc/cms-text';

/**
 * Props for the form, loaded from state
 */
export interface CmsDocumentTitleStateProps {
    text: CmsText;
}

export interface CmsDocumentOwnProps {
    titlePath: string;
    children?: React.ReactNode;
}

/**
 * All properties for SigninBox
 */
export type CmsDocumentTitleProps = CmsDocumentTitleStateProps & CmsDocumentOwnProps;

/**
 * The footer component
 */
export default function CmsDocumentTitle(props: CmsDocumentTitleProps): JSX.Element {
    let titleText = props.text.get(props.titlePath, '');
    
    useEffect(() => {
        // Update the document title using the browser API
        document.title = `KFAS: ${titleText}`;
    }, [titleText]);

    return (
        <>
            {props.children}
        </>    
    );
}
