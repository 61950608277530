import { CmsManifestMetaData } from '../misc/cms-manifest-metadata';
import * as Models from '../misc/models';
import { AppService } from './app-service';

/**
 * Get the custom privacy policy for a client
 */
export function GetCustomPolicy(clientId: number, languageId: number): Promise<Models.PrivacyModel> {
    let params: AppService.GetParam[] = [];
    params.push(new AppService.GetParam('id', clientId.toString()));
    params.push(new AppService.GetParam('languageId', languageId.toString()));
    params.push(new AppService.GetParam('type', 'json'));
    return AppService.GetThenToObject('privacyPolicy', params, Models.PrivacyModel);
}

/**
 * Post privacy policy acceptance back to server and get redirect URL back
 */
export function SubmitPrivacyPolicyResponse(token: string, accepted: boolean, customCheckboxAccepted: boolean | null, manifestMetaData: CmsManifestMetaData | null): Promise<string> {
    var body: string = JSON.stringify({ token: token, accepted: accepted, customCheckboxAccepted: customCheckboxAccepted, manifest: manifestMetaData });
    return AppService.PostThenTextResult('privacyPolicy', body)
        .then(r => {
            const json = JSON.parse(r);
            return json.authUrl;
        })
        .catch(r => { throw new Error ('error accepting privacy policy'); });
}