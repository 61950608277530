import 'isomorphic-fetch';
import * as queryString from 'query-string';

/**
 * App service to do actual API calls
 */

export namespace AppService {

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiVersion = process.env.REACT_APP_API_VERSION;
    const headers = { 'Content-Type': 'application/json' };

    export function PostThenToObject<T>(action: string, body: string, resultModel: { new (): T; }): Promise<T> {
        return Post(action, body)
            .then(r => r.json()).then(j => {
                let result: any = new resultModel();
                Object.assign(result, j);
                return result;
            });
    }

    /**
     * A get parameter
     */
    export class GetParam {
        id: string;
        value: string;

        constructor(id: string, value: string) {
            this.id = id;
            this.value = value;
        }

        forQuerystring(): string {
            return `${this.id}=${encodeURIComponent(this.value)}`;
        }
    }

    /**
     * Creates the full url using the environment variables of the api method to call.
     * Note: The action name might already be the full url, so just pass through as is, 
     * this would be the case for the CMS, to allow it to use a different url for the cloudfront CDN.
     * @param action The api action name to build the url with.
     */
    export function buildActionUrl(action: string): string {

        // Absolute path, no need to build with the existing configuration.
        if (action.substr(0, 4).toLowerCase() === 'http') {
            return action;
        }

        return buildActionUrlFromParts(apiUrl, apiVersion, action);
    }

    /**
     * Builds a complete url with the parts required for the api call. e.g. http://api.site.com/v1.0/action
     * @param apiUrlBase Base http path for the url
     * @param apiVersionNumber The version to use for the api
     * @param action The action name
     */
    export function buildActionUrlFromParts(apiUrlBase: string, apiVersionNumber: string, action: string) {
        return `${apiUrlBase}v${apiVersionNumber}/${action}`;
    }

    export function GetThenToObject<T>(action: string, params: GetParam[], resultModel: { new (): T; }): Promise<T> {
        return Get(action, params)
            .then(r => r.json()).then(j => {
                let result: any = new resultModel();
                Object.assign(result, j);
                return result;
            });
    }

    export function PostThenTextResult(action: string, body: string): Promise<string> {
        return Post(action, body)
            .then(r => r.text());
    }

    export function Post(action: string, body: string): Promise<Response> {
        let url = buildActionUrl(action);
        return fetch(url, {
            headers: headers,
            method: 'POST',
            credentials: 'include',
            body: body
        });
    }

    function Get(action: string, params: GetParam[]): Promise<Response> {
        let querystring = params.map(p => p.forQuerystring()).join('&');
        let url = buildActionUrl(action) + `?${querystring}`;
        return fetch(url, {
            method: 'GET'
        });
    }

    /**
     * Send a GET request with an object as the body and convert the result into another object.
     * @param action The API route to call
     * @param converter A function that accepts JSON (any data) and converts it to the required type. 
     * @param params Optional parameters to include in the query string for the request
     * @returns A Promise containing the result
     */
    export function getObject<TInput, TOutput>(
        action: string,
        converter: (json: {}) => TOutput, params?: TInput): Promise<TOutput> {
        return get(action, params as object)
            .then(response => converter(response.data));
    }

    /**
     * Send a GET request and handler the response.
     * @param action The relative API route to call.     
     * @param params Optional parameters to include in the query string for the request
     * @returns A Promise containing the response wrapped in a ResponseData object
     * 
     * Because fetch() will always return a response even if the status was 400+ then we have to handle the response ourselves and decide
     * whether to resolve/reject the promise. This means first extracting the relevant response data into a IResponseData object and
     * then deciding what to do. If the response did not return OK then a IResponseError object will be used to reject the Promise, meaning this
     * is what should be caught to handle that error case.
     */
    function get(action: string, params?: object): Promise<ResponseData> {
        let url = buildActionUrl(action);
        if (params) {
            url += '?' + queryString.stringify(params);
        }

        return fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(parseResponse)
            .then(handleResponse);
    }

    /**
     * Represents the details of a response that returned a non-successful HTTP code.
     */
    export interface ResponseError {
        /**
         * The HTTP status code of the response.
         */
        status: number;

        /**
         * The message contained in the response.
         */
        message: string;
    }

    /**
     * Represents the data contained in the response.
     */
    interface ResponseData {
        /**
         * The HTTP status code of the response.
         */
        readonly status: number;

        /**
         * Determines whether the response was successful.
         */
        readonly ok: boolean;

        /**
         * The data returned in the response.
         */
        /* tslint:disable-next-line:no-any */
        readonly data: any;
    }

    /**
     * Parses a response to extract useful information out and return a IResponseData object instead.
     * @param response The HTTP response to parse.
     * @returns The HTTP response to manage within the specific function handler
     */
    function parseResponse(response: Response): Promise<ResponseData> {
        return new Promise(resolve => response
            .json()
            .then(json => resolve({
                status: response.status,
                ok: response.ok,
                data: json
            })));
    }

    /**
     * Handles a response to either continue or reject the promise.
     * @param response A ResponseData object containing the relevant details.
     * @returns The HTTP response to manage within the specific function handler
     */
    function handleResponse(response: ResponseData): Promise<ResponseData> {
        return new Promise((resolve, reject) => {
            if (!response.ok) {
                return reject({
                    status: response.status,
                    message: response.data.message
                });
            }

            return resolve(response);
        });
    }
}