import { ThunkAction } from 'redux-thunk';
import State from './state';
import * as PrivacyPolicyActions from '../actions/privacy-policy';
import * as Models from '../misc/models';

/**
 * The default initial state
 */
export const PrivacyPolicyInitialState: State.PrivacyPolicy = {
    hasCheckedForCustomPolicy: false,
    customPrivacyHeader: '',
    customPrivacyStatement: '',
    customCheckboxAccepted: null,
    customCheckboxStatement: null,
    customTransferringDataStatement: null,
    customAcceptButtonStatement: null,
    customCancelButtonStatement: null,
    hasError: false
};

/**
 * Reducer for the privacy policy
 */
const PrivacyPolicyReducer =
    (state: State.PrivacyPolicy = PrivacyPolicyInitialState, action: PrivacyPolicyActions.Actions): State.PrivacyPolicy => {
        switch (action.type) {
            case PrivacyPolicyActions.GetCustomPrivacyPolicySuccessType:
                return {
                    ...state,
                    customPrivacyHeader: action.payload.customPrivacyHeader,
                    customPrivacyStatement: action.payload.customPrivacyStatement,
                    customCheckboxStatement: action.payload.customCheckboxStatement,
                    customTransferringDataStatement: action.payload.customTransferringDataStatement,
                    customAcceptButtonStatement: action.payload.customAcceptButtonStatement,
                    customCancelButtonStatement: action.payload.customCancelButtonStatement,
                    hasCheckedForCustomPolicy: true
                };
            case PrivacyPolicyActions.PrivacyPolicyResponseType:
                return {
                    ...state,
                    customCheckboxAccepted: action.payload.customCheckboxAccepted,
                    hasError: action.payload.hasError
                };
        default:
                return state;
        }
    };

export default PrivacyPolicyReducer;