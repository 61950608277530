import { EmptyObject, ExternalRedirect } from '../../misc/common';
import * as React from 'react';
import { CmsText } from '../../misc/cms-text';
import { ProjectExpiredRouteProps } from '../../containers/project-expired';
import { ExpiredProjectLoginMethod } from '../../misc/models';

/**
 * Props for the form, loaded from state
 */
export type ProjectExpiredStateProps = {
    componentText: any
};

/**
 * All combined props used for the form
 */
export type ProjectExpiredProps = ProjectExpiredStateProps & ProjectExpiredRouteProps;

/**
 * The component for showing expired project
 */
class ProjectExpired extends React.Component<ProjectExpiredProps, EmptyObject> {
    
    render() {
        let cmsText: CmsText = new CmsText(this.props.componentText, 'Project expired');
        let title: string;
        let statement: string;
        let explanation = cmsText.get('explanation', 'Please contact your administrator for further information.');

        switch (this.props.method) {
            case ExpiredProjectLoginMethod.ProjectRegistrationLink:
            case ExpiredProjectLoginMethod.InvitationLink:
                title = cmsText.get('linkExpiredTitle', 'This link has expired');
                statement = cmsText.get('linkExpiredStatement', 'The invitation you have used to reach this page has expired.');
                break;

            case ExpiredProjectLoginMethod.UsernameAndPassword:
                title = cmsText.get('loginExpiredTitle', 'This login has expired');
                statement = cmsText.get('loginExpiredStatement', 'The user details you have used to sign in have expired.');
                break;

            default:
                throw new Error(`Unsupported method: ${this.props.method}`);
        }

        return (
            <div className="content">
                <div>
                    <div className="panel-info">
                        <h1 className="form-control-static signinpanel-greeting" >
                            {title}
                        </h1>
                        <div className="panel-border" />
                        <h2 className="signinpanel-statement">
                            {statement}
                        </h2>
                        <h2 className="signinpanel-statement">
                            {explanation}
                        </h2>
                    </div>
                    <div className="form-button">
                        <button className="cancel" onClick={() => ExternalRedirect(window.location.origin)}>{cmsText.get('cancel', 'Cancel and Exit')}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectExpired;