import * as React from 'react';
import SetPasswordContainer from '../containers/set-password';
import ContentWrapper from '../components/contentWrapper';
import CmsDocumentTitle from '../containers/cms-document-title';

export default function SetPassword(): JSX.Element {
    return (
        <ContentWrapper>
            <CmsDocumentTitle titlePath="passwordSet.documentTitle">
                <SetPasswordContainer />
            </CmsDocumentTitle>
        </ContentWrapper>
    );
}