import { AppReducer } from './reducers/app';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, Middleware, Store, AnyAction } from 'redux';
import { createLogger } from 'redux-logger';
import State from './reducers/state';
import thunk from 'redux-thunk';
import { logReduxError } from './misc/error';
const reduxCatch = require('redux-catch');

// Define middleware to be used with Redux
let middlewares: Middleware[] = [];
middlewares.push(thunk);
middlewares.push(reduxCatch(logReduxError));

if (process.env.NODE_ENV !== `production`) {
  let logger: Middleware = createLogger();
  middlewares.push(logger);
}

const store: Store<State.All> = createStore<State.All, AnyAction, unknown, unknown>(AppReducer, applyMiddleware(...middlewares));
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>);
